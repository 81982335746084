import { ButtonFilter, ButtonToday, Section } from "./styles";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import dayjs from "dayjs";
import pt from "date-fns/locale/pt-BR";

registerLocale("pt-br", pt);

export const DatePikerFilter = ({
  paramsConfig,
  onChange,
  isLoading,
  showButtonFilter = true,
}) => {

  const startDate = paramsConfig.first_period ? new Date(`${paramsConfig.first_period} 12:00:00`) : null;
  const endDate = paramsConfig.final_period ? new Date(`${paramsConfig.final_period} 12:00:00`) : null;

  const handleFilter = () => {
    onChange({
      first_period: dayjs(startDate).format("YYYY-MM-DD"),
      final_period: dayjs(endDate).format("YYYY-MM-DD"),
    });
  };

  const handleToday = () => {
    const currencyDaySend = dayjs().format("YYYY-MM-DD");

    onChange({
      first_period: currencyDaySend,
      final_period: currencyDaySend,
    });
  };

  return (
    <>
      <Section>
        <strong className="font-weight-bold fs-5">Período</strong>
        <span>
          <p>De: </p>
          <ReactDatePicker
            locale={pt}
            dateFormat="dd/MM/yyyy"
            value={startDate}
            selected={startDate}
            onChange={(date) => {
              onChange({
                first_period: dayjs(date).format("YYYY-MM-DD"),
              });
            }}
            selectsStart
            startDate={startDate}
            disabled={isLoading}
            endDate={endDate}
            placeholderText="Data inicial"
          />
        </span>
        <span>
          <p className="ate">Até: </p>
          <ReactDatePicker
            locale={pt}
            dateFormat="dd/MM/yyyy"
            selected={endDate}
            value={endDate}
            onChange={(date) => {
              onChange({
                final_period: dayjs(date).format("YYYY-MM-DD"),
              });
            }}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            disabled={isLoading}
            placeholderText="Data final"
          />
        </span>

        {!!showButtonFilter && (
          <ButtonFilter
            type="button"
            onClick={() => handleFilter()}
            disabled={isLoading}
          >
            Filtrar
          </ButtonFilter>
        )}
        <ButtonToday
          type="button"
          onClick={() => handleToday()}
          disabled={isLoading}
        >
          Hoje
        </ButtonToday>
      </Section>
    </>
  );
};
