import React from "react";

const getMaxValue = (array) => {
  let max = { color: "", nome: "", value: 0 };
  array.forEach((item) => {
    if (item.value > max.value) {
      max = item;
    }
  });
  return max.value;
};

const FunnelChart = ({ data }) => {
  const totalWidth = 425;
  const totalHeight = 425;
  const height = totalHeight / data.length;
  const max = getMaxValue(data);

  return (
    <>
      <div
        style={{
          width: 0,
          height: 0,
          position: "absolute",
          right: "20px",
          borderLeft: `${totalWidth}px solid transparent`,
          borderRight: "0px solid transparent",
          borderTop: "425px solid #0000002e",
        }}
      ></div>
      <svg
        viewBox={`0 0 ${totalWidth} ${totalHeight}`}
        style={{ width: 425, marginRight: 8 }}
      >
        {data.map((item, index) => {
          const width = (item.value / max) * totalWidth;

          const y = index * height;
          const x = totalWidth - width;

          return (
            <g key={index}>
              <rect
                x={x}
                y={y}
                width={width}
                height={height}
                fill={item.color}
              />
              <text
                x={x + width / 2}
                y={y + height / 2}
                fill="white"
                text-anchor="middle"
                dominant-baseline="central"
                style={{
                  color: "white",
                  fontWeight: 700,
                  fontSize: 14,
                }}
              >
                {item.nome}
              </text>
            </g>
          );
        })}
      </svg>
    </>
  );
};

export default FunnelChart;
