import { apiProd } from "./api";
import { api } from "./api.v2";

export const vendasService = {
  listaVendaLojas: async (data) => {
    const response = api
      .get("/sales/global-by-store", {
        params: data,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
    return response;
  },
  listaVendasGrupo: async (data) => {
    const response = apiProd
      .get("/bi/vendas/vendas-por-grupo.php", {
        params: { entityData: data },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
    return response;
  },
  listaVendasFornecedor: async (data) => {
    const response = api
      .get("/sales/supplier", {
        params: { ...data },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
    return response;
  },
  listaVendasDiaSemana: async (data) => {
    const response = apiProd
      .get("/bi/vendas/vendas-por-dia.php", {
        params: { entityData: data },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
    return response;
  },
  listaVendasDesconto: async (data) => {
    const response = apiProd
      .get("/bi/vendas/vendas-desconto.php", {
        params: { entityData: data },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
    return response;
  },
  listaVendasPorVendedorGrupo: async (params) => {
    const response = api
      .get("/sales/sales-by-seller-group", {
        params,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
    return response;
  },
  buscaValorVendido: async (params) => {
    const response = api
      .get("/sales/summary/sold-value", {
        params,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
    return response;
  },
  buscaVendasNoPeriodo: async (params) => {
    const response = api
      .get("/sales/summary/number-of-sales", {
        params,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
    return response;
  },
  buscaCashback: async (params) => {
    const response = api
      .get("/sales/summary/cashback", {
        params,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
    return response;
  },
  homeSummary: async () => {
    const response = api
      .get("/sales/home-summary")
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
    return response;
  },
  homeChart: async () => {
    const response = api
      .get("/sales/home-chart")
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
    return response;
  },
};
