import { ButtonFilter, ButtonToday, Section } from "./styles";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import dayjs from "dayjs";
import pt from "date-fns/locale/pt-BR";
import { useEffect, useState } from "react";
registerLocale("es", pt);

export const DatePikerFilter = ({
  paramsConfig,
  onChange,
  isLoading,
  showButtonFilter = true,
}) => {
  const start = dayjs(paramsConfig.first_period, "DD/MM/YYYY");
  const end = dayjs(paramsConfig.final_period, "DD/MM/YYYY");
  const [startDate, setStartDate] = useState(new Date(start));
  const [endDate, setEndDate] = useState(new Date(end));

  const handleFilter = () => {
    onChange({
      first_period: dayjs(startDate).format("YYYY-MM-DD"),
      final_period: dayjs(endDate).format("YYYY-MM-DD"),
    });
  };

  const handleToday = () => {
    const currencyDaySend = dayjs().format("YYYY-MM-DD");
    const currencyDay = dayjs().format("YYYY-MM-DD");
    setStartDate(new Date(currencyDay));
    setEndDate(new Date(currencyDay));
    onChange({
      first_period: currencyDaySend,
      final_period: currencyDaySend,
    });
  };

  useEffect(() => {
    setStartDate(new Date(start));
    setEndDate(new Date(end));
  }, [paramsConfig]);

  return (
    <>
      <Section>
        <strong className="font-weight-bold fs-5">Período</strong>
        <span>
          <p>De: </p>
          <ReactDatePicker
            locale={pt}
            dateFormat="dd/MM/yyyy"
            value={startDate}
            selected={startDate}
            onChange={(date) => {
              setStartDate(date)
              onChange({
                first_period: dayjs(date).format("YYYY-MM-DD"),
              });
            }}
            selectsStart
            startDate={startDate}
            disabled={isLoading}
            endDate={endDate}
          />
        </span>
        <span>
          <p className="ate">Até: </p>
          <ReactDatePicker
            locale={pt}
            dateFormat="dd/MM/yyyy"
            selected={endDate}
            value={endDate}
            onChange={(date) => {
              setEndDate(date)
              onChange({
                final_period: dayjs(date).format("YYYY-MM-DD"),
              });
            }}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            disabled={isLoading}
          />
        </span>

        {!!showButtonFilter && (
          <ButtonFilter
            type="button"
            onClick={() => handleFilter()}
            disabled={isLoading}
          >
            Filtrar
          </ButtonFilter>
        )}
        <ButtonToday
          type="button"
          onClick={() => handleToday()}
          disabled={isLoading}
        >
          Hoje
        </ButtonToday>
      </Section>
    </>
  );
};
