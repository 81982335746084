import { Add } from "@mui/icons-material";
import { Autocomplete, Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { useContext, useState } from "react";
import { FormContext } from "../../../pages/financeiro/parametros-de-bonificacao/premiacoes";
import { useFormContext } from "react-hook-form";

const opcoesCargo = [
    { label: 'Gerente', value: 'Gerente' },
    { label: 'Líder', value: 'Líder' }
]

export const TableLideranca = ({ base, name }) => {
    const [valueSelect, setValueSelect] = useState(null);

    const { watch, setValue } = useFormContext()
    const infoLideranca = watch(`${name}`)
    console.log({ infoLideranca });

    const {
        funcionariosNaoSelecionados,
        setFuncionariosNaoSelecionados
    } = useContext(FormContext);

    const optionsAuto = funcionariosNaoSelecionados.filter(itemFunc => base === itemFunc.base).map((item) => ({
        label: `${item?.codFunc} - ${item?.nomeFunc} - Loja ${item?.codLoja}`, value: item,
    }));

    const handleAddLideranca = () => {
        if (!valueSelect) {
            return;
        }
        const newInfoLideranca = [...infoLideranca]
        newInfoLideranca.push({
            base: valueSelect.value.base,
            codLoja: valueSelect.value.codLoja,
            codFunc: valueSelect.value.codFunc,
            nomeFunc: valueSelect.value.nomeFunc,
            cargo: "GERENTE"
        })
        console.log({ newInfoLideranca });

        setFuncionariosNaoSelecionados(funcionariosNaoSelecionados.filter(item => item.codFunc !== valueSelect.value.codFunc))
        setValue(name, newInfoLideranca)
        setValueSelect(null)
    };

    const handleRemoveFuncionario = (funcToDelete) => {
        const newInfoLideranca = infoLideranca.filter(item => item.codFunc !== funcToDelete.codFunc)
        setValue(name, newInfoLideranca)
        console.log({ funcToDelete, funcionariosNaoSelecionados });

        const newFuncionarios = [...funcionariosNaoSelecionados]
        newFuncionarios.push({
            base: funcToDelete.base,
            cargo: "",
            codFunc: funcToDelete.codFunc,
            codLoja: funcToDelete.codLoja,
            nomeFunc: funcToDelete.nomeFunc
        })
        setFuncionariosNaoSelecionados(newFuncionarios)
    };

    const handleChangeCargo = (value, codFunc) => {
        const newInfoLideranca = [...infoLideranca]
        const index = newInfoLideranca.findIndex(item => item.codFunc === codFunc)
        newInfoLideranca[index].cargo = value.value
        console.log({ newInfoLideranca });
        setValue(name, newInfoLideranca)
    }

    return <>
        <Box
            display={"flex"}
            gap={1}
            py={1}
            sx={{
                ".Mui-disabled": {
                    opacity: 0.7,
                    backgroundColor: "#ff7aae !important",
                    color: "white",
                },
            }}
        >
            <Autocomplete
                disablePortal
                value={valueSelect}
                options={optionsAuto}
                sx={{ width: "100%" }}
                onChange={(e, newValue) => {
                    setValueSelect(newValue);
                }}
                noOptionsText="Nenhum resultado encontrado"
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Pesquise pelo nome do funcionário"
                        size="small"
                    />
                )}
            />
            <Button
                onClick={handleAddLideranca}
                variant="contained"
                color="aprincipal"
                size="small"
            >
                <Add fontSize="inherit" />
            </Button>
        </Box>
        <TableContainer
            component={Paper}
            variant="outlined"
            sx={{
                mt: 0.5,
                mb: 1,
            }}
        >
            <Table size="small" aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell>Base</TableCell>
                        <TableCell>Loja</TableCell>
                        <TableCell>Código</TableCell>
                        <TableCell>Nome</TableCell>
                        <TableCell>Cargo</TableCell>
                        <TableCell>Ações</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        infoLideranca.map((item) => {
                            return <TableRow key={item.codFunc}>
                                <TableCell>{item.base}</TableCell>
                                <TableCell>{item.codLoja}</TableCell>
                                <TableCell>{item.codFunc}</TableCell>
                                <TableCell>{item.nomeFunc}</TableCell>
                                <TableCell>
                                    <Autocomplete
                                        disablePortal
                                        clearIcon={null}
                                        value={opcoesCargo.find(opcao => opcao?.value?.toLowerCase() === item?.cargo?.toLowerCase())}
                                        options={opcoesCargo}
                                        sx={{ width: "100%" }}
                                        onChange={(e, newValue) => {
                                            handleChangeCargo(newValue, item.codFunc)
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                size="small"
                                            />
                                        )}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Button
                                        onClick={() => handleRemoveFuncionario(item)}
                                    >
                                        Remover
                                    </Button>
                                </TableCell>
                            </TableRow>
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    </>
}

