import { formatarNumero } from "../../../util/number";

export const dataChart = ({
  labelsChart,
  dataChartBarFromApi,
  dataChartProjetadaFromApi,
  dataMetaDiamante,
  dataMetaOuro,
  dataChartLineFromApi,
  dataDescontoAplicadoFromApi,
}) => ({
  labels: labelsChart,
  datasets: [
    {
      type: "line",
      label: "Projetada",
      borderColor: "rgb(00, 00, 00)",
      borderWidth: 2,
      fill: false,
      data: dataChartProjetadaFromApi,
      tension: 0,
      borderDash: [10, 5],
      clip: true,
      clamp: true,
      xAxisID: "A",
      datalabels: {
        anchor: "end",
        display: "auto",
        color: "#000",
        align: (context) => {
          if (
            dataChartBarFromApi[context.dataIndex] <
            dataChartProjetadaFromApi[context.dataIndex]
          ) {
            return "-90";
          } else {
            return "90";
          }
        },
        labels: {
          title: {
            font: {
              weight: "bold",
              size: 15,
            },
          },
        },
        formatter: function (value, context) {
          return parseFloat(value).toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        },
      },
    },
    {
      type: "line",
      label: "Meta",
      borderColor: "#c4302b",
      borderWidth: 2,
      fill: false,
      data: dataChartBarFromApi,
      tension: 0,
      showLine: false,
      clip: true,
      xAxisID: "A",
      datalabels: {
        anchor: "end",
        display: true,
        color: "#c4302b",
        align: (context) => {
          if (
            dataChartBarFromApi[context.dataIndex] <
            dataChartProjetadaFromApi[context.dataIndex]
          ) {
            return "90";
          } else {
            return "-90";
          }
        },
        labels: {
          title: {
            font: {
              weight: "bold",
              size: 15,
            },
          },
        },
      },
    },
    {
      type: "line",
      label: "Diamante",
      borderColor: "#6395ec",
      borderWidth: 5,
      fill: false,
      data: dataMetaDiamante,
      tension: 0,
      showLine: false,
      clip: true,
      xAxisID: "A",
      datalabels: {
        anchor: "end",
        display: false,
        color: "#6395ec",
        align: (context) => {
          if (
            dataChartBarFromApi[context.dataIndex] <
            dataChartProjetadaFromApi[context.dataIndex]
          ) {
            return "90";
          } else {
            return "-90";
          }
        },
        labels: {
          title: {
            font: {
              weight: "bold",
              size: 15,
            },
          },
        },
      },
    },
    {
      type: "line",
      label: "Ouro",
      borderColor: "#FFD700",
      borderWidth: 5,
      fill: false,
      data: dataMetaOuro,
      tension: 0,
      showLine: false,
      clip: true,
      xAxisID: "A",
      datalabels: {
        anchor: "end",
        display: false,
        color: "#FFD700",
        align: (context) => {
          if (
            dataChartBarFromApi[context.dataIndex] <
            dataChartProjetadaFromApi[context.dataIndex]
          ) {
            return "90";
          } else {
            return "-90";
          }
        },
        labels: {
          title: {
            font: {
              weight: "bold",
              size: 15,
            },
          },
        },
      },
    },
    {
      type: "bar",
      label: "Realizada",
      backgroundColor: "rgba(54, 162, 235, 1)",
      data: dataChartLineFromApi,
      borderColor: "transparent",
      borderWidth: 2,
      xAxisID: "B",
      datalabels: {
        anchor: "start",
        display: true,
        color: "rgba(54, 162, 235, 1)",
        align: "start",
        labels: {
          title: {
            font: {
              weight: "bold",
              size: 15,
            },
          },
        },
      },
    },
    {
      type: "bar",
      label: "Desconto",
      backgroundColor: "rgba(255, 102, 0, 0.3)",
      borderColor: "rgba(255, 102, 0, 0.3)",
      borderWidth: 1,
      borderDash: [10, 5],
      data: dataDescontoAplicadoFromApi,
      xAxisID: "B",
      datalabels: {
        anchor: "end",
        display: "auto",
        color: "rgba(255, 255, 255, 1)",
        align: "start",
        labels: {
          title: {
            font: {
              weight: "bold",
              size: 12,
            },
            padding: 0,
          },
        },
        formatter: function () {
          return "";
        },
      },
    },
  ],
});

export const dataChartDesconto = ({
  labelsChartDesconto,
  dataDescontoParaGrafico,
  labelsChartDescontoCod,
  labelsChartCod,
  dataChartLineFromApi,
}) => ({
  labels: labelsChartDesconto,
  datasets: [
    {
      type: "bar",
      label: "Desconto",
      backgroundColor: "rgba(255, 102, 0, 0.3)",
      borderColor: "rgba(255, 102, 0, 0.3)",
      borderWidth: 1,
      borderDash: [10, 5],
      data: dataDescontoParaGrafico,
      datalabels: {
        anchor: "start",
        display: "auto",
        color: "rgba(000, 000, 000, 1)",
        align: "start",
        labels: {
          title: {
            font: {
              weight: "bold",
              size: 12,
            },
            padding: 0,
          },
        },
        formatter: function (value, context) {
          // let valorDesconto = dataDescontoAplicadoFromApi[context.dataIndex] - dataChartLineFromApi[context.dataIndex];
          value = parseFloat(value) / 1000;
          return (
            parseFloat(value).toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + "K"
          );
        },
      },
    },
    {
      type: "line",
      label: "DescontoPorcentagem",
      backgroundColor: "rgba(255, 102, 0, 0.3)",
      borderColor: "rgba(255, 102, 0, 0.3)",
      borderWidth: 0,
      tension: 0,
      showLine: false,
      borderDash: [0, 0],
      data: dataDescontoParaGrafico,
      fill: false,
      datalabels: {
        anchor: "end",
        display: "auto",
        color: "rgba(000, 000, 000, 1)",
        align: "end",
        labels: {
          title: {
            font: {
              weight: "bold",
              size: 12,
            },
            padding: 0,
          },
        },
        formatter: function (value, context) {
          let lojaAtual = labelsChartDescontoCod[context.dataIndex];
          let indexLojaGraficoPrincipal;
          labelsChartCod.map((a, i) => {
            if (a == lojaAtual) {
              indexLojaGraficoPrincipal = i;
            }
          });

          let totalVenda = parseFloat(
            dataChartLineFromApi[indexLojaGraficoPrincipal]
          );

          // value = dataDescontoParaGrafico[context.dataIndex] - dataChartLineFromApi[context.dataIndex];
          let valorDescontoPorcentagem =
            totalVenda + value > 0 ? (value / (totalVenda + value)) * 100 : 0;
          return formatarNumero(valorDescontoPorcentagem, false) + "%";
        },
      },
    },
  ],
});
