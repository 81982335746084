import { Comparator, textFilter } from "react-bootstrap-table2-filter";
import { formatCurrency, formatDecimal } from "../../../util/number";

export const colunasResultados = [
  {
    dataField: "key",
    text: "",
    hidden: true,
  },
  {
    dataField: "base",
    text: "Base",
    filter: textFilter({
      placeholder: "Base",
      comparator: Comparator.EQ
    }),
    headerStyle: { width: "60px" },
    headerClasses: "column-1",
    classes: "column-1",
  },
  {
    dataField: "codLoja",
    text: "CodLoja",
    filter: textFilter({
      placeholder: "Loja",
      comparator: Comparator.EQ

    }),
    headerStyle: { width: "60px" },
  },
  {
    dataField: "codFunc",
    text: "CodFunc",
    filter: textFilter({
      placeholder: "Funcionário",
      comparator: Comparator.EQ
    }),
    headerStyle: { width: "60px" },
  },
  {
    dataField: "nomeFunc",
    text: "Nome",
    filter: textFilter({
      placeholder: "Nome",
    }),
    headerStyle: { width: "100px" },
  },
  {
    dataField: "cargo",
    text: "Cargo",
    filter: textFilter({
      placeholder: "Cargo",
    }),
    headerStyle: { width: "100px" },
  },
  {
    dataField: "meta",
    text: "Meta",
    headerStyle: { width: "100px" },
    formatter: (cell) => formatCurrency(cell),
  },
  {
    dataField: "resultado",
    text: "Resultado",
    headerStyle: { width: "120px" },
    formatter: (cell, row) => {
      return row.iconePremiacao + " " + formatCurrency(cell);
    },
  },
  {
    dataField: "premiacaoReal",
    text: "Premiação R$",
    headerStyle: { width: "100px" },
    formatter: (cell) => formatCurrency(cell),
  },
];

export const colunasResultadosPorPeriodo = [
  {
    dataField: "id",
    text: "",
    hidden: true,
  },
  {
    dataField: "descricaoPeriodo",
    text: "Período",
    headerStyle: { width: "100px" },
    headerClasses: "column-1",
    classes: "column-1",
  },
  {
    dataField: "nomeFunc",
    text: "Nome",
    headerStyle: { width: "100px" },
  },
  {
    dataField: "meta",
    text: "Meta",
    headerStyle: { width: "100px" },
    formatter: (cell) => formatCurrency(cell),
  },
  {
    dataField: "resultado",
    text: "Resultado",
    headerStyle: { width: "120px" },
    formatter: (cell, row) => {
      return row.iconePremiacao + " " + formatCurrency(cell);
    },
  },
  {
    dataField: "premiacao",
    text: "Premiação %",
    headerStyle: { width: "100px" },
    formatter: (cell, row) =>
      row?.resumo ? "" : formatDecimal(cell * 100) + "%",
  },
  {
    dataField: "premiacaoReal",
    text: "Premiação R$",
    headerStyle: { width: "100px" },
    formatter: (cell) => formatCurrency(cell),
  },
];

export const rowClasses = (row) => {
  let classes = null;

  if (row.iconePremiacao.includes("🥇")) {
    classes = "cor-ouro";
  } else if (row.iconePremiacao.includes("🚀")) {
    classes = "cor-meta";
  } else if (row.iconePremiacao.includes("💎")) {
    classes = "cor-diamante";
  }

  return classes;
};
