import { agruparPor, converterLoja, tratamentoDados } from "../util/loja";
import api, { apiProd } from "./api";
import { api as apiV2 } from "./api.v2";

export const baseService = {
  listaBases: async ({ baseAcesso }) => {
    const response = api
      .get("/powerbi/request.php", {
        params: {
          entity: "entidades",
          entityData: { baseAcesso },
          entityFunction: "listaBase",
        },
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);
    return response;
  },
  getQualidade: async (params) => {
    const response = apiV2
      .get("/inventory/quality", {
        params: params,
      })
      .then((response) => {
        if (response.status === 200) {
          return response?.data;
        } else {
          return null;
        }
      });
    return response;
  },
  getSair: async ({ params }) => {
    const { id, token } = params;
    const response = api
      .get("/powerbi/request.php", {
        params: {
          entity: "login",
          entityData: JSON.stringify({ id, token }),
          entityFunction: "getSair",
        },
      })
      .then((data) => {
        if (data?.data?.success) {
          return data?.data?.success;
        } else {
          return data?.data?.success;
        }
      });
    return response;
  },
  getEstoqueMes: async (base) => {
    const response = apiV2
      .get("/inventory/by-month", {
        params: {
          codBase: base,
        },
      })
      .then((data) => {
        if (data?.data) {
          const {
            dadosVenda,
            dadosEstoque,
            dadosEstoqueTransito,
            estoqueAguardandoEntrega,
          } = data.data;
          let codLojaVendas = dadosVenda.map((item) => item.CodLoja);
          let codLojaEstoque = dadosEstoque.map((item) => item.CodLoja);
          let codLojaEstoqueTransito = dadosEstoqueTransito.map(
            (item) => item.CodLoja
          );
          // let codLojaEstoqueAguardandoEntrega = estoqueAguardandoEntrega.map(item => item.CodLoja)
          codLojaVendas = codLojaVendas.filter(
            (acc, item) => codLojaVendas.indexOf(acc) === item
          );
          codLojaEstoque = codLojaEstoque.filter(
            (acc, item) => codLojaEstoque.indexOf(acc) === item
          );
          codLojaEstoqueTransito = codLojaEstoqueTransito.filter(
            (acc, item) => codLojaEstoqueTransito.indexOf(acc) === item
          );
          // codLojaEstoqueAguardandoEntrega = codLojaEstoqueAguardandoEntrega.filter((acc, item) => codLojaEstoqueAguardandoEntrega.indexOf(acc) === item)
          let lojasDisponiveis = [
            codLojaEstoque,
            codLojaVendas,
            codLojaEstoqueTransito,
          ].reduce((acc, cur) => acc.concat(cur));
          lojasDisponiveis = lojasDisponiveis.filter(
            (acc, item) => lojasDisponiveis.indexOf(acc) === item
          );
          lojasDisponiveis = lojasDisponiveis.map((item) => parseInt(item));
          lojasDisponiveis.sort((a, b) => {
            if (a > b) return 1;
            if (a < b) return -1;
            return 0;
          });
          lojasDisponiveis = lojasDisponiveis.map((item) =>
            converterLoja(parseInt(item))
          );
          const dadosAux = tratamentoDados(
            dadosEstoque,
            dadosVenda,
            dadosEstoqueTransito,
            estoqueAguardandoEntrega
          );
          return {
            dadosGerais: dadosAux,
            dadosPorLoja: {
              dadosVenda,
              dadosEstoque,
              dadosEstoqueTransito,
              estoqueAguardandoEntrega,
            },
            lojasDisponiveis: lojasDisponiveis,
          };
        } else {
          return false;
        }
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
    return response;
  },
  getEstoqueMesSubGrupo: async (idGrupo, base) => {
    const response = apiV2
      .get("/inventory/by-month-subgroup", {
        params: {
          codBase: base,
          groupId: idGrupo,
        },
      })
      .then(async (data) => {
        if (data.data) {
          let {
            dadosVenda,
            dadosEstoque,
            dadosEstoqueTransito,
            estoqueAguardandoEntrega,
          } = data.data;
          let dadosAux = [],
            dadosGeraisVendas = {},
            dadosGeraisEstoque = {},
            dadosGeraisEstoqueTransito = {},
            dadosGeraisEstoqueAguardandoEntrega = {};
          dadosGeraisVendas = agruparPor(dadosVenda, "codSubGp");
          dadosGeraisEstoque = agruparPor(dadosEstoque, "codSubGp");
          dadosGeraisEstoqueTransito = agruparPor(
            dadosEstoqueTransito,
            "codSubGp"
          );
          dadosGeraisEstoqueAguardandoEntrega = agruparPor(
            estoqueAguardandoEntrega,
            "codSubGp"
          );
          let codLojaEstoqueTransito = dadosEstoqueTransito.map(
            (item) => item.CodLoja
          );

          dadosGeraisVendas = Object.keys(dadosGeraisVendas).map((item) => {
            let custoVendas = 0,
              qtdVendas = 0;
            dadosGeraisVendas[item].forEach((event) => {
              custoVendas += parseFloat(event.custoVendas)
                ? parseFloat(event.custoVendas)
                : 0;
              qtdVendas += parseFloat(event.qtdVendas)
                ? parseFloat(event.qtdVendas)
                : 0;
            });
            return {
              codSubGp: dadosGeraisVendas[item][0].codSubGp,
              NomeGrupo: dadosGeraisVendas[item][0].NomeGrupo,
              custoVendas: custoVendas,
              qtdVendas: qtdVendas,
            };
          });

          dadosGeraisEstoqueTransito = Object.keys(
            dadosGeraisEstoqueTransito
          ).map((item) => {
            let custoEstoque = 0,
              qtdEstoque = 0;
            dadosGeraisEstoqueTransito[item].forEach((event) => {
              custoEstoque += parseFloat(event.custoEstoque)
                ? parseFloat(event.custoEstoque)
                : 0;
              qtdEstoque += parseFloat(event.qtdEstoque)
                ? parseFloat(event.qtdEstoque)
                : 0;
            });
            return {
              codSubGp: dadosGeraisEstoqueTransito[item][0].codSubGp,
              NomeGrupo: dadosGeraisEstoqueTransito[item][0].NomeGrupo,
              custoEstoque: custoEstoque,
              qtdEstoque: qtdEstoque,
            };
          });

          dadosGeraisEstoqueAguardandoEntrega = Object.keys(
            dadosGeraisEstoqueAguardandoEntrega
          ).map((item) => {
            let custoEstoque = 0,
              qtdEstoque = 0;
            dadosGeraisEstoqueAguardandoEntrega[item].forEach((event) => {
              custoEstoque += parseFloat(event.custoEstoque)
                ? parseFloat(event.custoEstoque)
                : 0;
              qtdEstoque += parseFloat(event.qtdEstoque)
                ? parseFloat(event.qtdEstoque)
                : 0;
            });
            return {
              codSubGp: dadosGeraisEstoqueAguardandoEntrega[item][0].codSubGp,
              NomeGrupo: dadosGeraisEstoqueAguardandoEntrega[item][0].NomeGrupo,
              custoEstoque: custoEstoque,
              qtdEstoque: qtdEstoque,
            };
          });

          dadosGeraisEstoque = Object.keys(dadosGeraisEstoque).map((item) => {
            let custoEstoque = 0,
              qtdEstoque = 0;
            dadosGeraisEstoque[item].forEach((event) => {
              custoEstoque += parseFloat(event.custoEstoque)
                ? parseFloat(event.custoEstoque)
                : 0;
              qtdEstoque += parseFloat(event.qtdEstoque)
                ? parseFloat(event.qtdEstoque)
                : 0;
            });
            return {
              codSubGp: dadosGeraisEstoque[item][0].codSubGp,
              NomeGrupo: dadosGeraisEstoque[item][0].NomeGrupo,
              custoEstoque: custoEstoque,
              qtdEstoque: qtdEstoque,
            };
          });

          let arrayMesclado = await Promise.all(
            dadosGeraisEstoque.map((itemEstoque) => {
              const itemEstoqueTransito = dadosGeraisEstoqueTransito.find(
                (itemTransito) =>
                  itemTransito?.codSubGp === itemEstoque.codSubGp
              );
              const itemEstoqueAguardando =
                dadosGeraisEstoqueAguardandoEntrega.find(
                  (itemAguardando) =>
                    itemAguardando?.codSubGp === itemEstoque.codSubGp
                );
              const itemVenda = dadosGeraisVendas.find(
                (itemVendas) => itemVendas.codSubGp === itemEstoque.codSubGp
              );
              // if (itemVenda) {
              return {
                ...itemEstoque,
                NomeGrupo: itemVenda?.NomeGrupo
                  ? itemVenda?.NomeGrupo
                  : itemEstoque.NomeGrupo,
                CustoVendas: itemVenda?.custoVendas ? itemVenda.custoVendas : 0,
                QtdVendas: itemVenda?.qtdVendas ? itemVenda.qtdVendas : 0,
                qtdEstoqueTransito: itemEstoqueTransito?.qtdEstoque
                  ? itemEstoqueTransito?.qtdEstoque
                  : 0,
                custoEstoqueTransito: itemEstoqueTransito?.custoEstoque
                  ? itemEstoqueTransito?.custoEstoque
                  : 0,
                qtdEstoqueAguardandoEntrega: itemEstoqueAguardando?.qtdEstoque
                  ? itemEstoqueAguardando?.qtdEstoque
                  : 0,
                custoEstoqueAguardandoEntrega:
                  itemEstoqueAguardando?.custoEstoque
                    ? itemEstoqueAguardando?.custoEstoque
                    : 0,
              };
              // }
              // return {
              //   ...itemEstoque,
              //   CustoVendas: 0,
              //   QtdVendas: 0
              // }
            })
          );
          arrayMesclado = arrayMesclado.sort((a, b) =>
            a.custoEstoque > b.custoEstoque
              ? -1
              : a.custoEstoque < b.custoEstoque
              ? 1
              : 0
          );
          const todosSubGrupos = arrayMesclado;
          arrayMesclado = arrayMesclado.filter((item, index) => {
            if (index < 15) {
              return item;
            }
          });

          return {
            dataAux: arrayMesclado,
            dataLojasSg: {
              dadosVenda,
              dadosEstoque,
              dadosEstoqueTransito,
              estoqueAguardandoEntrega,
            },
            todosSubGrupos: todosSubGrupos,
          };
        } else {
          return false;
        }
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
    return response;
  },
  getEstoqueForncedorContasPagar: async (
    periodoReferencia,
    nivelAcesso,
    base
  ) => {
    const response = await apiProd
      .get("/bi/estoque/estoque-fornecedor-contas-pagar.php", {
        params: {
          entityData: JSON.stringify({
            nivelAcesso: JSON.stringify(nivelAcesso),
            base,
          }),
        },
      })
      .then((data) => {
        if (data.data.data) {
          const {
            contasPagar,
            custo,
            vendas,
            dadosEstoque,
            dadosEstoqueTransito,
          } = data.data.data;
          let dadosGeraisCusto = agruparPor(custo, "CodFor");
          let dadosGeraisEstoque = agruparPor(dadosEstoque, "CodGrp3");
          let dadosGeraisEstoqueTransito = agruparPor(
            dadosEstoqueTransito,
            "CodGrp3"
          );

          dadosGeraisCusto = Object.keys(dadosGeraisCusto).map(
            (item, index) => {
              let custo = 0;
              dadosGeraisCusto[item].forEach((item) => {
                custo += parseFloat(item.custo);
              });
              return {
                CodFor: dadosGeraisCusto[item][0].CodFor,
                NomeFor: dadosGeraisCusto[item][0].NomeFor,
                custo: custo,
              };
            }
          );

          dadosGeraisEstoque = Object.keys(dadosGeraisEstoque).map((item) => {
            let custoEstoque = 0,
              qtdEstoque = 0;
            dadosGeraisEstoque[item].forEach((event) => {
              custoEstoque += parseFloat(event.custoEstoque)
                ? parseFloat(event.custoEstoque)
                : 0;
              qtdEstoque += parseFloat(event.qtdEstoque)
                ? parseFloat(event.qtdEstoque)
                : 0;
            });
            return {
              codSubGp: dadosGeraisEstoque[item][0].CodGrp3,
              NomeGrupo: dadosGeraisEstoque[item][0].NomeGrupo3,
              custoEstoque: custoEstoque,
              qtdEstoque: qtdEstoque,
            };
          });

          dadosGeraisEstoqueTransito = Object.keys(
            dadosGeraisEstoqueTransito
          ).map((item) => {
            let custoEstoque = 0,
              qtdEstoque = 0;
            dadosGeraisEstoqueTransito[item].forEach((event) => {
              custoEstoque += parseFloat(event.custoEstoque)
                ? parseFloat(event.custoEstoque)
                : 0;
              qtdEstoque += parseFloat(event.qtdEstoque)
                ? parseFloat(event.qtdEstoque)
                : 0;
            });
            return {
              codSubGp: dadosGeraisEstoqueTransito[item][0].CodGrp3,
              NomeGrupo: dadosGeraisEstoqueTransito[item][0].NomeGrupo3,
              custoEstoque: custoEstoque,
              qtdEstoque: qtdEstoque,
            };
          });

          vendas.map((aaa) => {
            if (aaa.CodGrp3 === "292") {
            }
          });

          let arrayMesclado = dadosGeraisCusto.map((itemCusto) => {
            let arrayAux = { ...itemCusto };

            const itemVenda = contasPagar.find(
              (itemCp) => itemCp.CodFor === itemCusto.CodFor
            );
            if (itemVenda) {
              arrayAux = {
                ...arrayAux,
                totalPagar: parseFloat(itemVenda.totalPagar),
              };
            } else {
              arrayAux = {
                ...arrayAux,
                totalPagar: 0,
              };
            }
            if (vendas && vendas?.length > 0) {
              // Adicionando parte de vendas
              var itemDadosVenda = vendas.filter(
                (itemV) => itemV.CodGrp3 === arrayAux.CodFor
              );
              itemDadosVenda = itemDadosVenda.reduce(
                (acc, itemDV) => {
                  return {
                    custoVendas:
                      parseFloat(acc.custoVendas) +
                      parseFloat(itemDV.custoVendas),
                    qtdVendas:
                      parseFloat(acc.qtdVendas) + parseFloat(itemDV.qtdVendas),
                  };
                },
                {
                  custoVendas: 0,
                  qtdVendas: 0,
                }
              );
              if (itemDadosVenda) {
                arrayAux = {
                  ...arrayAux,
                  CustoVendas: !isNaN(parseFloat(itemDadosVenda.custoVendas))
                    ? parseFloat(itemDadosVenda.custoVendas)
                    : 0,
                  qtdVendas: parseFloat(itemDadosVenda.qtdVendas),
                };
              } else {
                arrayAux = {
                  ...arrayAux,
                  CustoVendas: 0,
                  qtdVendas: 0,
                };
              }
            }
            if (
              (dadosEstoque && dadosEstoque?.length > 0) ||
              (dadosEstoqueTransito && dadosEstoqueTransito?.length > 0)
            ) {
              // Adicionando parte de vendas
              const itemEstoque = dadosGeraisEstoque.find(
                (itemEst) => itemEst.codSubGp === itemCusto.CodFor
              );

              const itemEstoqueTransito = dadosGeraisEstoqueTransito.find(
                (itemEst) => itemEst.codSubGp === itemCusto.CodFor
              );

              let custoItemEstoque = Number(itemEstoque?.custoEstoque) || 0;
              custoItemEstoque = itemEstoqueTransito?.custoEstoque
                ? custoItemEstoque + Number(itemEstoqueTransito?.custoEstoque)
                : custoItemEstoque;

              let qtdItemEstoque = Number(itemEstoque?.qtdEstoque) || 0;
              qtdItemEstoque = itemEstoqueTransito?.qtdEstoque
                ? qtdItemEstoque + Number(itemEstoqueTransito?.qtdEstoque)
                : qtdItemEstoque;

              const dadosEstoque = {
                custoEstoque: custoItemEstoque || 0,
                qtdEstoque: qtdItemEstoque || 0,
              };

              if (itemEstoque || itemEstoqueTransito) {
                arrayAux = {
                  ...arrayAux,
                  custoEstoque: parseFloat(dadosEstoque.custoEstoque),
                  qtdEstoque: parseFloat(dadosEstoque.qtdEstoque),
                };
              } else {
                arrayAux = {
                  ...arrayAux,
                  custoEstoque: 0,
                  qtdEstoque: 0,
                };
              }
            }
            return arrayAux;
          });
          // arrayMesclado.concat(arrayMesclado, )
          //percorre o array de contas a pagar, e retorna as informações dos fornecedores que não foram
          //encontrados dentro do array de custo
          let naoEncontrados = contasPagar.filter((itemCp) => {
            const itemVenda = arrayMesclado.find(
              (itemCusto) => itemCusto.CodFor === itemCp.CodFor
            );
            if (!itemVenda) {
              return itemCp;
            }
          });
          //percore o array dos fornecedores não encontrados e retorna o valor de custo zerado
          naoEncontrados = naoEncontrados.map((item) => {
            var arrayAux = { ...item };

            const itemDadosVenda = vendas.find(
              (itemV) => itemV.CodGrp3 === arrayAux.CodFor
            );
            if (itemDadosVenda) {
              arrayAux = {
                ...arrayAux,
                CustoVendas: !isNaN(parseFloat(itemDadosVenda.custoVendas))
                  ? parseFloat(itemDadosVenda.custoVendas)
                  : 0,
                qtdVendas: parseFloat(itemDadosVenda.qtdVendas),
              };
            } else {
              arrayAux = {
                ...arrayAux,
                CustoVendas: 0,
                qtdVendas: 0,
              };
            }

            if (
              (dadosEstoque && dadosEstoque?.length > 0) ||
              (dadosEstoqueTransito && dadosEstoqueTransito?.length > 0)
            ) {
              // Adicionando parte de vendas
              const itemEstoque = dadosGeraisEstoque.find(
                (itemEst) => itemEst.codSubGp === arrayAux.CodFor
              );

              const itemEstoqueTransito = dadosGeraisEstoqueTransito.find(
                (itemEst) => itemEst.codSubGp === arrayAux.CodFor
              );

              let custoItemEstoque = Number(itemEstoque?.custoEstoque) || 0;
              custoItemEstoque = itemEstoqueTransito?.custoEstoque
                ? custoItemEstoque + Number(itemEstoqueTransito?.custoEstoque)
                : custoItemEstoque;

              let qtdItemEstoque = Number(itemEstoque?.qtdEstoque) || 0;
              qtdItemEstoque = itemEstoqueTransito?.qtdEstoque
                ? qtdItemEstoque + Number(itemEstoqueTransito?.qtdEstoque)
                : qtdItemEstoque;

              const dadosEstoque = {
                custoEstoque: custoItemEstoque || 0,
                qtdEstoque: qtdItemEstoque || 0,
              };

              if (itemEstoque || itemEstoqueTransito) {
                arrayAux = {
                  ...arrayAux,
                  custoEstoque: parseFloat(dadosEstoque.custoEstoque),
                  qtdEstoque: parseFloat(dadosEstoque.qtdEstoque),
                };
              } else {
                arrayAux = {
                  ...arrayAux,
                  custoEstoque: 0,
                  qtdEstoque: 0,
                };
              }
            }

            return {
              ...arrayAux,
              custo: 0,
              totalPagar: parseFloat(item.totalPagar),
            };
          });

          //concatena os array Com os valores de custo que já foi unificado com o valor de contas a pagar
          //com o array de não encontrados
          let dataGeral = arrayMesclado.concat(naoEncontrados);
          //Ordena o array pelo maior valor de contas a pagar
          dataGeral = dataGeral.sort((a, b) =>
            a.custo > b.custo ? -1 : a.custo < b.custo ? 1 : 0
          );
          return { data: dataGeral, dataOriginal: { contasPagar, custo } };
        } else {
          return data?.data?.status ? data.data : false;
        }
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
    return response;
  },
  getVendasPorDia: async (
    periodoReferencia,
    base,
    nivelAcesso,
    tipo = "input"
  ) => {
    const { fim, ano, mes, diaSemana } = periodoReferencia;
    let loading = true,
      error = false;

    const response = await apiProd
      .get("/bi/vendas/vendas-por-dia.php", {
        params: {
          entityData: JSON.stringify({
            inicio: fim,
            fim,
            ano,
            mes,
            tipo,
            nivelAcesso,
            base,
          }),
        },
      })
      .then((data) => {
        const dataAtual = fim.split("-");
        const dadosHoje = data.data.data.vendas.filter((item) => {
          if (
            item.ano === dataAtual[0] &&
            String(diaSemana + 1) === item.diaSemana
          ) {
            return item;
          }
        });

        //busca a loja com o  valor de vendas maior e a loja com   a mior numero de pedidos
        let maiorValor = {
          VlrTotal: 0,
        };
        let maiorQTD = {
          totalPedidos: 0,
        };
        for (let i = 0; i < dadosHoje.length; i++) {
          if (
            parseFloat(dadosHoje[i].VlrTotal) > parseFloat(maiorValor.VlrTotal)
          ) {
            maiorValor = dadosHoje[i];
          }
          if (
            parseInt(dadosHoje[i].totalPedidos) >
            parseInt(maiorQTD.totalPedidos)
          ) {
            maiorQTD = dadosHoje[i];
          }
        }

        let totalPedidos = 0,
          valorTotal = 0;
        dadosHoje.forEach((item) => {
          totalPedidos += parseInt(item.totalPedidos)
            ? parseInt(item.totalPedidos)
            : 0;
          valorTotal += parseFloat(item.VlrTotal)
            ? parseFloat(item.VlrTotal)
            : 0;
        });
        const repesentatividadeValor =
          valorTotal > 0 && maiorValor?.VlrTotal > 0
            ? (parseFloat(maiorValor?.VlrTotal) / valorTotal) * 100
            : 0;
        maiorValor = {
          ...maiorValor,
          VlrTotal: new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(maiorValor.VlrTotal),
          CodLoja: maiorValor?.CodLoja
            ? converterLoja(maiorValor?.CodLoja)
            : "",
          repesentatividadeValor: repesentatividadeValor.toFixed(2),
        };
        const repesentatividadeQTD =
          valorTotal > 0 && maiorQTD?.totalPedidos > 0
            ? (parseInt(maiorQTD?.totalPedidos) / totalPedidos) * 100
            : 0;
        maiorQTD = {
          ...maiorQTD,
          VlrTotal: new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(maiorQTD.VlrTotal),
          CodLoja: maiorQTD?.CodLoja ? converterLoja(maiorQTD?.CodLoja) : "",
          repesentatividadeQTD: repesentatividadeQTD.toFixed(2),
        };
        let ticketMedio = valorTotal / totalPedidos;
        valorTotal = new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(valorTotal);
        ticketMedio = new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(ticketMedio);
        return { valorTotal, totalPedidos, ticketMedio, maiorValor, maiorQTD };
      })
      .finally(() => {
        return (loading = false);
      })
      .catch(() => {
        return (error = true);
      });
    return { response, loading, error };
  },
  searchProduct: async ({
    nivelAcesso,
    baseAcesso,
    searchTerm,
    page,
    orderBy,
    orderByType,
  }) => {
    const response = await apiProd
      .get("/bi/estoque/consulta-produtos.php", {
        params: {
          entityData: JSON.stringify({
            nivelAcesso,
            baseAcesso,
            searchTerm,
            page,
            orderBy,
            orderByType,
          }),
        },
      })
      .then((data) => {
        if (data?.data?.data) {
          const total = data.data.total ? data.data.total : 0;
          function agruparPor(objetoArray, propriedade) {
            return objetoArray.reduce(function (acc, obj) {
              let key = obj[propriedade];
              if (!acc[key]) {
                acc[key] = [];
              }
              acc[key].push(obj);
              return acc;
            }, {});
          }
          let grupoDeProdutos = agruparPor(data?.data?.data, "CodProd");
          const grupoDeProdutosArray = Object.entries(grupoDeProdutos);
          const resultFormatted = grupoDeProdutosArray?.map((item) => {
            const itemData = item[1];
            let itemDataFormatted = {};
            const estoqueAtualTotal = itemData?.reduce((acc, item) => {
              return (
                acc + (item?.EstoqueAtual ? Number(item?.EstoqueAtual) : 0)
              );
            }, 0);

            const estoqueTransitoTotal = itemData?.reduce((acc, item) => {
              return (
                acc +
                (item?.EstoqueTransito ? Number(item?.EstoqueTransito) : 0)
              );
            }, 0);

            const estoquePorLocalGeral = itemData?.reduce((acc, item) => {
              return item.EstoquePorLocal
                ? [...acc, ...item.EstoquePorLocal]
                : acc;
            }, []);

            itemData?.forEach((item2, index) => {
              if (index === 0) {
                itemDataFormatted = {
                  codProd: item2.CodProd,
                  descricao: item2.Descricao,
                  base: item2.Base,
                  precoVenda: item2.PrecoVenda,
                  estoqueAtual: estoqueAtualTotal,
                  estoqueTransito: estoqueTransitoTotal,
                  grupo: item2.Grupo,
                  subGrupo: item2.SubGrupo,
                  precoPromocao: Number(item2.PrecoFinalPromocaoTemporaria),
                  descontoPromocao: Number(item2.DescontoPromEspecial),
                  promocao: item2.Promocao,
                  dataFimPromocao: item2.DataFinalPromocao,
                  estoquePorLocal: estoquePorLocalGeral,
                  tipoDesconto: item2.TipoDecontoPromEspecial,
                  dadosPorBase: itemData,
                };
              }
            });

            return itemDataFormatted;
          });
          return {
            data: resultFormatted,
            total,
          };
        } else {
          return [];
        }
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
    return response;
  },
};
