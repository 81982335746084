import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { converterNumero } from "../../util/loja";
import { useFormContext } from "react-hook-form";
import dayjs from "dayjs";
import { FaCircle } from "react-icons/fa";
import { useContext } from "react";
import { FormContext } from "../../pages/financeiro/metas/cadastro-metas";

export const TableSemanal = ({ open, data }) => {

  const { watch } = useFormContext();
  const { periodsSelects } = useContext(FormContext);
  const dataPeriods = watch(`${data.codLoja}.periodos`);
  return (
    <TableContainer
      component={Paper}
      variant="outlined"
      sx={{
        mt: 0.5,
        mb: 1,
        display: open ? "none" : "table",
      }}
    >
      <Table size="small" aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>Período</TableCell>
            <TableCell>Valor Vendas</TableCell>
            <TableCell>Ticket Médio</TableCell>
            <TableCell>Qtd Pedidos</TableCell>
            <TableCell>Itens por venda</TableCell>
            <TableCell>Clientes cadastrados</TableCell>
            <TableCell>Dias úteis</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.values(dataPeriods).map((item, index) => {

            const periodDates = periodsSelects.find(
              (period) => period.periodKey === item.periodKey
            );

            // const infoPeriodo = getValues(`${data.codLoja}.periodos.${item.periodKey}`);
            return (
              <TableRow>
                <TableCell>
                  {`${dayjs(periodDates.start).format(
                    "DD/MM"
                  )} - ${dayjs(periodDates.end).format("DD/MM")}`}
                  <Box component={"span"} ml={1}>
                    <FaCircle
                      fontSize={"10px"}
                      color={
                        !!item?.loja?.metaEspecial
                          ? "green"
                          : "red"
                      }
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  {converterNumero(
                    +item?.loja?.metaVenda
                  )}
                </TableCell>
                <TableCell>
                  {converterNumero(
                    +item?.loja?.metaTM
                  )}
                </TableCell>
                <TableCell>
                  {item?.loja?.metaQtdPedidos}
                </TableCell>
                <TableCell>
                  {item?.loja?.metaItensPorVenda}
                </TableCell>
                <TableCell>
                  {item?.loja?.metaClientesCadastrados}
                </TableCell>

                <TableCell>
                  {item?.loja?.diasUteis}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
