import { Box, Typography } from "@mui/material";
import Select from "react-select";
export const SelectLoja = ({ options, onChange, value, isDisabled, label = "Lojas" }) => {
  const onChageSelect = (data) => {
    onChange(data);
  };
  return (
    <Box display={"grid"} alignItems={"center"} width={"100%"}>
      <Typography textAlign={"initial"} fontWeight={"bold"} component={"span"}>
        {label}
      </Typography>
      <Select
        value={value}
        isDisabled={isDisabled}
        onChange={onChageSelect}
        options={options}
        placeholder="Selecione..."
        classNames={{
          control: (state) => state.isFocused && "borderIsFocus",
        }}
      />
    </Box>
  );
};
