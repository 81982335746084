import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Collapse,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { TableMain } from "./TableMain";
import DatePicker from "react-datepicker";

import { useContext, useEffect, useState } from "react";
import { FormEditarMetasMensal } from "./FormEditMetaMensal";
import { FormContext } from "../../pages/financeiro/metas/cadastro-metas";
import { useFormContext } from "react-hook-form";
import dayjs from "dayjs";
import { CollapseFuncionarios } from "./colapseFuncionarios";
import { CollapseMetaSemanal } from "./CollapseMetaSemanal";
import { FaExclamationTriangle } from "react-icons/fa";
import { ButtonExpand } from "../ExpandMore";

export const CardStore = ({ item }) => {

  const [mostrarDiasNaoTrabalhados, setMostrarDiasNaoTrabalhados] =
    useState(false);
  const [openEditMensal, setOpenEditMensal] = useState(false);
  const [openStoreCollapse, setOpenStoreCollapse] = useState(false);
  const { getValues, setValue } = useFormContext();

  const [selectedDatesOff, setSelectedDatesOff] = useState(getValues(`${item.codLoja}.diasRemovidos`) ?? []);

  const storeInfo = getValues(`${item.codLoja}`);
  const {
    funcionariosNaoSelecionados,
    currentCollapsedStore,
    setCurrentCollapsedStore
  } = useContext(FormContext);

  const handleEditMensalClick = () => setOpenEditMensal((old) => !old);

  const optionsAuto = funcionariosNaoSelecionados.filter(itemFunc => item.base === itemFunc.base).map((item) => ({
    label: `${item?.codFunc} - ${item?.nomeFunc} - Loja ${item?.codLoja}`, value: item,
  }));

  const handleDelete = (index) => {
    const dateTemp = getValues(`${item.codLoja}.diasRemovidos`);
    const dateFiltered = dateTemp.filter((item, i) => i !== index);
    setValue(`${item.codLoja}.diasRemovidos`, dateFiltered);
    setSelectedDatesOff(dateFiltered);
  };

  const handleSelectDayOff = (date) => {

    const dateTemp = getValues(`${item.codLoja}.diasRemovidos`) ?? [];

    if (!dateTemp.some(d => dayjs(d).isSame(date, 'day'))) {
      setValue(`${item.codLoja}.diasRemovidos`, [...dateTemp, date]);
      setSelectedDatesOff(prev => [...prev, date]);
    }
  }

  const getFormattedDate = (date) => {
    if (dayjs(date).isValid()) {
      return dayjs(date).format("DD/MM/YYYY")
    }
    return null
  }

  const handleCollapseStore = () => {
    setCurrentCollapsedStore(old => old === item.codLoja ? null : item.codLoja);
  }

  useEffect(() => {
    if (currentCollapsedStore === item.codLoja) {
      setOpenStoreCollapse(true);
    } else {
      setOpenStoreCollapse(false);
    }
  }, [currentCollapsedStore]);

  return (
    <Card variant="outlined">
      <CardActions
        sx={{
          pb: "4px",
          pr: "16px",
          cursor: "pointer",
        }}
        disableSpacing
        onClick={handleCollapseStore}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          width={"100%"}
          alignItems={"center"}
        >
          <Typography
            sx={{ fontSize: 14 }}
            color="text.primary"
            fontWeight={"500"}
            mb={0}
            component={"span"}
            s
            gutterBottom
          >
            <ButtonExpand
              handleExpandClick={() => { }}
              expanded={openStoreCollapse}
            />
            {item.codLoja} - {item.loja} (Base {item.base})
            {item.new && <Box component='span' sx={{ color: 'red', ml: 2 }} ><FaExclamationTriangle /> Meta não cadastrada</Box>}
          </Typography>
        </Stack>
      </CardActions>
      {currentCollapsedStore === item.codLoja && <Collapse in={openStoreCollapse}>
        <CardContent
          sx={{
            pt: 0,
            pb: "4px",
          }}
        >
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            width={"100%"}
            alignItems={"flex-end"}
          >
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              fontWeight={"500"}
              component={"span"}
              mb={0}
              gutterBottom
            >
              Meta mensal
            </Typography>
            <Stack direction={"row"} gap={2}>
              <Button
                onClick={handleEditMensalClick}
                size="small"
                variant="outlined"
              >
                {openEditMensal ? "Fechar" : "Editar Meta mensal"}
              </Button>
            </Stack>
          </Stack>
          <TableMain data={storeInfo} open={!openEditMensal} />
          <FormEditarMetasMensal
            onClickSave={handleEditMensalClick}
            data={item}
            open={openEditMensal}
          />
        </CardContent>
        <CollapseFuncionarios
          optionsAuto={optionsAuto}
          item={storeInfo}
        />

        <CollapseMetaSemanal
          optionsAuto={optionsAuto}
          item={item}
        />

        <CardContent>
          <Stack direction={"row"} my={1} justifyContent={"space-between"}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  fontWeight={"500"}
                  component={"p"}
                  mb={0}
                  gutterBottom
                >
                  Dias removidos
                </Typography>
                <Stack direction={"row"} gap={1} flexWrap={"wrap"}>
                  {selectedDatesOff.map((item, index) => (
                    <Chip label={getFormattedDate(item)} onDelete={() => handleDelete(index)} />
                  ))}
                </Stack>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  sx={{ marginLeft: 'auto', display: 'block' }}
                  onClick={() => setMostrarDiasNaoTrabalhados((old) => !old)}
                  size="small"
                  variant="outlined"
                >
                  {mostrarDiasNaoTrabalhados ? 'Fechar calendário' : 'Adicionar dias não trabalhados'}
                </Button>
                <Collapse unmountOnExit in={mostrarDiasNaoTrabalhados} sx={{ width: 'fit-content', marginLeft: 'auto' }}>
                  <Box component={Paper} variant="outlined"
                  >
                    <DatePicker
                      selectedDates={selectedDatesOff ?? []}
                      selectsMultiple={true}
                      onChange={handleSelectDayOff}
                      shouldCloseOnSelect={false}
                      disabledKeyboardNavigation
                      inline
                    />
                  </Box>
                </Collapse>
              </Grid>
            </Grid>
          </Stack>
        </CardContent>
      </Collapse>}
    </Card>
  );
};
