import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { converterNumero } from "../../util/loja";
import { ButtonExpand } from "../ExpandMore";
import { useContext, useState } from "react";

import { FaCircle, FaEdit } from "react-icons/fa";
import dayjs from "dayjs";
import { useFormContext } from "react-hook-form";
import { FormRowFuncionarioPeriodo } from "./FormRowFuncionarioPeriodo";
import { FormContext } from "../../pages/financeiro/metas/cadastro-metas";


export const TableFuncionariosPeriodos = ({
  data,
  handleOpenEditFunc,
}) => {

  const { getValues, watch } = useFormContext()
  const { periodsSelects } = useContext(FormContext)

  const infoFunc = watch(`${data.codLoja}.funcionarios`)

  return (
    <TableContainer
      component={Paper}
      variant="outlined"
      sx={{
        mt: 0.5,
        mb: 1,
      }}
    >
      <Table size="small" aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Nome</TableCell>
            <TableCell>Valor Vendas</TableCell>
            <TableCell>Ticket Medio</TableCell>
            <TableCell>Qtd Pedidos</TableCell>
            <TableCell>Item por venda</TableCell>
            <TableCell>Clientes cadastrados</TableCell>
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.values(infoFunc).map((item, index) => {

            const infoFuncMonth = getValues(`${item.codLoja}.funcionarios.${item.codFunc}`)
            const infoPeriodos = getValues(`${item.codLoja}.periodos`)
            const infoCurrentFunc = Object.values(infoPeriodos).map(itemPeriodo => {

              const periodDates = periodsSelects.find(
                (period) => period.periodKey === itemPeriodo.periodKey
              );
              return { ...itemPeriodo.funcionarios[item.codFunc], periodKey: itemPeriodo.periodKey, start: periodDates.start, end: periodDates.end }
            })

            return (
              <Rows
                key={item.codFunc + index}
                data={{
                  ...infoFuncMonth,
                  periodos: infoCurrentFunc
                }}
                handleOpenEditFunc={handleOpenEditFunc}
              />
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const Rows = ({ data }) => {
  const [expanded, setExpanded] = useState(false);
  const [openEditFunc, setOpenEditFunc] = useState(false);
  const { getValues } = useFormContext();
  const handleExpandClick = () => {
    if (!expanded) {
      setOpenEditFunc(true);
    }
    setExpanded(!expanded);
  };

  const handleOpenEditFunc = () => {
    if (!expanded) {
      setExpanded(true);
      setOpenEditFunc(true);
      return;
    }
    setOpenEditFunc((old) => !old);
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <ButtonExpand
            handleExpandClick={handleExpandClick}
            expanded={expanded}
          />
        </TableCell>
        <TableCell>{data.nomeFunc}</TableCell>
        <TableCell>{converterNumero(data.metaVenda)}</TableCell>
        <TableCell>{converterNumero(data.metaTM)}</TableCell>
        <TableCell>{data.metaQtdPedidos}</TableCell>
        <TableCell>{data.metaItensPorVenda}</TableCell>
        <TableCell>{data.metaClientesCadastrados}</TableCell>
        <TableCell>
          <Stack direction={"row"}>
            <IconButton onClick={handleOpenEditFunc} size="small">
              <FaEdit />
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse
            in={expanded}
            sx={{
              display: openEditFunc ? "none" : "block",
            }}
            timeout="auto"
          >
            <FormRowFuncionarioPeriodo
              data={data.periodos}
              codLoja={data.codLoja}
              codFunc={data.codFunc}
              expanded={expanded}
              handleExpandClick={handleExpandClick}
            />
          </Collapse>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse
            in={expanded}
            sx={{
              display: openEditFunc ? "block" : "none",
            }}
            timeout="auto"
          >
            <Table size="small" aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell>Periodo</TableCell>
                  <TableCell>Valor Vendas</TableCell>
                  <TableCell>Ticket Medio</TableCell>
                  <TableCell>Qtd Pedidos</TableCell>
                  <TableCell>Item por venda</TableCell>
                  <TableCell>Clientes cadastrados</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.periodos.map((itemFuncPeriodo, index) => {
                  const infoFuncCurrentPeriod = getValues(
                    `${data.codLoja}.periodos.${itemFuncPeriodo.periodKey}.funcionarios.${itemFuncPeriodo.codFunc}`
                  );

                  return (
                    <TableRow>
                      <TableCell>
                        {`${dayjs(itemFuncPeriodo?.start).format(
                          "DD/MM"
                        )} - ${dayjs(itemFuncPeriodo?.end).format(
                          "DD/MM"
                        )}`}
                        <Box component={"span"} ml={1}>
                          <FaCircle
                            fontSize={"10px"}
                            color={
                              !!infoFuncCurrentPeriod?.metaEspecial
                                ? "green"
                                : "red"
                            }
                          />
                        </Box>
                      </TableCell>
                      <TableCell>
                        {converterNumero(+infoFuncCurrentPeriod.metaVenda)}
                      </TableCell>
                      <TableCell>
                        {converterNumero(+infoFuncCurrentPeriod.metaTM)}
                      </TableCell>
                      <TableCell>
                        {infoFuncCurrentPeriod.metaQtdPedidos}
                      </TableCell>
                      <TableCell>
                        {infoFuncCurrentPeriod.metaItensPorVenda}
                      </TableCell>
                      <TableCell>
                        {infoFuncCurrentPeriod.metaClientesCadastrados}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
