import { Box } from "@mui/material";
import { FormTipoMeta } from "../formTipoMeta";

export const CollapseTipoMeta = ({ codLoja, bonusKey, handleSave }) => {

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          py: 0.5,
        }}
      >
        <FormTipoMeta
          name={`${codLoja}.bonusAtual.${bonusKey}`}
          callbackSave={handleSave}
        />
      </Box>
    </>
  );
};
