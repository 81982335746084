import {
  Collapse,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { converterNumero } from "../../util/loja";
import { useState } from "react";

import { FormRowFuncionario } from "./FormRowFuncionario";
import { BiSolidTrash } from "react-icons/bi";
import { FaEdit } from "react-icons/fa";
import { useFormContext } from "react-hook-form";

export const TableFuncionarios = ({
  name,
  handleOpenEditFunc,
  handleRemove,
}) => {
  const { watch } = useFormContext();
  const rowData = watch(name) || {};
  return (
    <TableContainer
      component={Paper}
      variant="outlined"
      sx={{
        mt: 0.5,
        mb: 1,
      }}
    >
      <Table size="small" aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>Funcionário</TableCell>
            <TableCell>Valor Vendas</TableCell>
            <TableCell>Ticket Medio</TableCell>
            <TableCell>Qtd Pedidos</TableCell>
            <TableCell>Item por venda</TableCell>
            <TableCell>Clientes cadastrados</TableCell>
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.values(rowData).map((item, index) => (
            <Rows
              key={item.codFunc + index}
              data={item}
              handleRemove={handleRemove}
              handleOpenEditFunc={handleOpenEditFunc}
              name={name}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const Rows = ({ data, handleRemove, name }) => {

  const [openEditFunc, setOpenEditFunc] = useState(false);

  const handleToggleEditFunc = () => {
    setOpenEditFunc((old) => !old);
  };

  return (
    <>
      <TableRow>
        <TableCell>{`${data.codFunc} - ${data.nomeFunc}`}</TableCell>
        <TableCell>{converterNumero(data.metaVenda)}</TableCell>
        <TableCell>{converterNumero(data.metaTM)}</TableCell>
        <TableCell>{data.metaQtdPedidos}</TableCell>
        <TableCell>{data.metaItensPorVenda}</TableCell>
        <TableCell>{data.metaClientesCadastrados}</TableCell>
        <TableCell>
          <Stack direction={"row"}>
            <IconButton onClick={() => handleRemove(data)} size="small">
              <BiSolidTrash color="red" />
            </IconButton>
            <IconButton onClick={handleToggleEditFunc} size="small">
              <FaEdit />
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse
            in={openEditFunc}
            timeout="auto"
          >
            <FormRowFuncionario
              data={data}
              name={`${name}.${data.codFunc}`}
              onSave={handleToggleEditFunc}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
