import React, { useEffect, useState } from "react";
import "chartjs-plugin-datalabels";
import Title from "../../components/title";

import ReactFullscreen from "react-easyfullscreen";
import "react-data-table-component-extensions/dist/index.css";
import ReactSpeedometer from "react-d3-speedometer";
import { Col, Row } from "react-bootstrap";
import { colunasMetasGerais2 } from "../../util/tables";

import TabelaNext from "../../components/componentteste/TabelaNext";

import { LegandaMetas } from "../../components/LegendaMetas/LegandaMetas";
import toast from "react-hot-toast";

import { ModalMetasDirecinarPagVendedor } from "../../components/ModalMetasDirecinarPagVendedor/ModalMetasDirecinarPagVendedor";
import { PermissionsPage } from "../../util/permissionsPage";
import { LoadingTable } from "../../components/Loading/LoadingTable";
import {
  Box,
  Button,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { MultiSelectFilterLoja } from "../../components/FilterLojaMetas/MultiSelectFilterLoja";
import { MultiSelectFilterBases } from "../../components/FilterLojaMetas/MultiSelectFilterBases";
import { SelectFilterMonth } from "../../components/FilterLojaMetas/SelectMes";
import { Bar1 } from "../../components/GraficosMetasGerais/Bar1";
import { ChartBar } from "../../components/MetasGerais/ChartBar";
import {
  optionsChartBarDesconto,
  optionsChartBarMetas,
} from "../../components/MetasGerais/ChartBar/chartOptions";
// import { dataChart } from "../../dataCharts/metas-vendedor";
import { dataChart, dataChartDesconto } from "../../components/MetasGerais/ChartBar/chartData";
import ErrorToastHandler from "../../util/ErrorToastHandler";
import metas from "../../services/metas";
import { DatePikerFilter } from "../../components/DatePikerFilterV2";
import { ButtonFilter } from "../../components/buttonFilter";

const defaultValues = {
  bases: [],
  lojas: [],
  periodo: null,
  first_period: null,
  final_period: null,
};

export function MetasGeraisPorPerido() {
  const theme = useTheme();

  const isSm = useMediaQuery(theme.breakpoints.up("sm"));

  const [paramsConfig, setParamsConfig] = useState(defaultValues);
  const [dataDescontoParaGrafico, setDataDescontoParaGrafico] = useState([]);
  const [labelsChart, setlabelsChart] = useState([]);
  const [labelsChartCod, setlabelsChartCod] = useState([]);
  const [labelsChartDesconto, setlabelsChartDesconto] = useState([]);
  const [labelsChartDescontoCod, setlabelsChartDescontoCod] = useState([]);
  const [dataDescontoAplicadoFromApi, setDataDescontoAplicadoFromApi] =
    useState([]);
  const [dataChartBarFromApi, setDataChartBarFromApi] = useState([]);
  const [dataMetaOuro, setDataMetaOuro] = useState([]);
  const [dataMetaDiamante, setDataMetaDiamante] = useState([]);
  const [dataChartLineFromApi, setDataChartLineFromApi] = useState([]);
  const [metaTotal, setMetaTotal] = useState(0);
  const [realizadoTotal, setRealizadoTotal] = useState(0);
  const [dataChartProjetadaFromApi, setDataChartProjetadaFromApi] = useState(
    []
  );
  const [dataTableFromApi, setDataTableFromApi] = useState([]);
  const [dadosDisponiveis, setDadosDisponiveis] = useState(false);
  const [carregando, setCarregando] = useState(true);
  const [periodosDisponiveis, setPeriodosDisponiveis] = useState([]);
  const [lojasDisponiveis, setLojasDisponiveis] = useState([]);
  const [basesDisponiveis, setBasesDisponiveis] = useState([]);
  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleClose = () => setModal({ ...modal, active: false });

  function direcionarParaMetasPorVendedorasLojaEspecifica(loja) {
    setModal({ ...modal, active: true, loja });
    return;
  }

  async function preencherDados(periodId = null) {
    setCarregando(true);
    setIsLoading(true);
    window.scrollTo(0, 0);
    const toastId = toast.loading("Esperando dados do RAD");

    try {
      const response = await metas.getPeriodTargetView(periodId || paramsConfig.periodo, {
        periodoInputInicio: paramsConfig.first_period,
        periodoInputFim: paramsConfig.final_period,
        bases: paramsConfig.bases,
        lojas: paramsConfig.lojas,
      });
      console.log(response);

      if (response?.status !== 200) {
        toast.error("Não há conexão com o RAD", {
          id: toastId,
          duration: 7000,
          style: {
            borderRadius: "0.25rem",
            background: "#333",
            color: "#fff",
          },
        });
        setCarregando(false);
        setIsLoading(false);
        setDadosDisponiveis(false);
        return;
      }
      toast.success("Dados recebidos", {
        id: toastId,
      });
      setData(response.data)

      setDadosDisponiveis(true);

    } catch (error) {
    } finally {
      setIsLoading(false);
      setCarregando(false);
    }
  }

  const setData = (data) => {
    const {
      dadosTabela,
      dadosGraficoDesconto,
      dadosGraficoMetas,
      labelsChartCode,
    } = data;

    setDataChartBarFromApi(dadosGraficoMetas?.meta || []);
    setDataMetaOuro(dadosGraficoMetas?.metaOuro || []);
    setDataMetaDiamante(dadosGraficoMetas?.metaDiamante || []);
    setDataChartLineFromApi(dadosGraficoMetas?.realizado || []);
    setDataDescontoAplicadoFromApi(dadosGraficoMetas?.desconto || []);
    setDataChartProjetadaFromApi(dadosGraficoMetas?.projetado || []);
    setlabelsChart(dadosGraficoMetas?.labels || []);
    setDataTableFromApi(dadosTabela);
    setlabelsChartCod(labelsChartCode);
    setDataDescontoParaGrafico(dadosGraficoDesconto?.data || []);
    setlabelsChartDesconto(dadosGraficoDesconto?.labels || []);
    setlabelsChartDescontoCod(dadosGraficoDesconto?.codLojas || []);

    setMetaTotal(data.metaTotal);
    setRealizadoTotal(data.realizadoTotal);
  }

  const handleBase = (value) => {
    console.log(value);
    console.log(lojasDisponiveis);

    setParamsConfig(old => ({
      ...old,
      bases: value
    }));
  };

  async function filtrarEmpresa(lojasSelecionadas) {
    window.scrollTo(0, 0);
    console.log(lojasSelecionadas)
    setParamsConfig(old => ({
      ...old,
      lojas: lojasSelecionadas
    }));
  }

  function selecionarPeriodo(item) {
    setParamsConfig((old) => ({
      ...old,
      periodo: item,
    }));
  }

  const onChangeDatePicker = (value) => {

    setParamsConfig((old) => ({
      ...old,
      ...value,
    }));
  };

  const getAvailablePeriods = async () => {
    try {
      const response = await metas.getAvailablePeriods();
      return response.data;
    } catch (error) {
      new ErrorToastHandler(error).showErrorToast();
    }
  };

  const getAvailableStoresAndBases = async (periodId) => {
    try {
      const response = await metas.getAvailableStoresAndBases(periodId);
      return response.data;
    } catch (error) {
      new ErrorToastHandler(error).showErrorToast();
    }
  };

  const getInfoInitialDataPage = async () => {
    const periods = await getAvailablePeriods();
    console.log(periods);

    setPeriodosDisponiveis(periods);
    const currentPeriod = periods[0]?.id;
    const currentTargetId = periods[0]?.targetId;

    const baseStoreResponse = await getAvailableStoresAndBases(currentTargetId);
    setParamsConfig(old => ({
      ...old,
      periodo: currentPeriod
    }));

    setBasesDisponiveis(baseStoreResponse?.bases || []);
    const stores = baseStoreResponse.lojas;
    console.log(stores);
    const formatLojasDisponiveis = stores.map((item) => {
      return {
        codLoja: item.value,
        nomeLoja: item.label,
        base: item.base,
        quantidadeVendedorasAbaixoMeta: [],
      };
    });
    setLojasDisponiveis(formatLojasDisponiveis);
    preencherDados(currentPeriod);
  };

  useEffect(() => {
    getInfoInitialDataPage();
  }, []);

  return (
    <>
      <ModalMetasDirecinarPagVendedor show={modal} handleClose={handleClose} />

      {PermissionsPage("metasVendasSemanal") && (
        <>
          <Box>
            <Title title="META SEMANAL LOJA" />
          </Box>
          <Box
            display={"flex"}
            flexDirection={["column", "row"]}
            width={"100%"}
          >
            <Box
              width={["100%", "auto"]}
              display={["flex", "block"]}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <DatePikerFilter
                paramsConfig={paramsConfig}
                onChange={onChangeDatePicker}
                isLoading={isLoading}
                showButtonFilter={false}
              />
            </Box>
            <Box width={"100%"} pl={[0, 2]}>
              <Row>
                <Col xs={12} md={3}>
                  <SelectFilterMonth
                    periodoReferencia={paramsConfig.periodo}
                    periodosDisponiveis={periodosDisponiveis}
                    selecionarPeriodo={selecionarPeriodo}
                    isDisabled={false}
                  />
                </Col>
                <Col xs={12} md={9}>
                  <MultiSelectFilterBases
                    basesDisponiveis={basesDisponiveis}
                    basesSelecionadas={paramsConfig.bases}
                    onChange={handleBase}
                    disabled={carregando}
                  />
                  <Typography variant="caption" component={"span"}>
                    *Atenção: Quando nenhuma base estiver selecionada, todas as
                    bases serão retornadas.*
                  </Typography>
                </Col>
              </Row>

              {isSm && (
                <>
                  <MultiSelectFilterLoja
                    labelsTable={lojasDisponiveis.filter(
                      (item) => {
                        if (!paramsConfig.bases?.length) {
                          return true
                        }
                        return paramsConfig.bases.includes(item.base)
                      }
                    )}
                    infoSelect={paramsConfig.lojas}
                    functionEmpresa={filtrarEmpresa}
                    direcionarParaMetasPorVendedorasLojaEspecifica={
                      direcionarParaMetasPorVendedorasLojaEspecifica
                    }
                    isDisabled={carregando}
                  />
                  <Typography variant="caption" component={"span"}>
                    *Atenção: Quando nenhuma loja estiver selecionada, todas as
                    lojas serão retornadas.*
                  </Typography>
                </>
              )}
              <Row>
                <Col xs={12} style={{ marginTop: 16 }}>
                  <ButtonFilter
                    type="button"
                    onClick={() => preencherDados()}
                    disabled={carregando}
                  >
                    Filtrar
                  </ButtonFilter>
                </Col>
              </Row>
            </Box>
          </Box>
          {carregando ? (
            <Box
              width={"100%"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              height={"60vh"}
            >
              <LoadingTable isLoading={carregando} />
            </Box>
          ) : (
            <>
              {dadosDisponiveis && (
                <>
                  <ReactFullscreen>
                    {({ ref, onRequest, onExit }) => (
                      <div
                        ref={ref}
                        style={{
                          backgroundColor: "#fff",
                          overflowX: "hidden",
                        }}
                      >
                        <Box
                          component={Paper}
                          variant="outlined"
                          display={"flex"}
                          alignItems={"center"}
                          flexDirection={"column"}
                          p={2}
                          my={2}
                          overflow="scroll"
                        >
                          {isSm && (
                            <Box
                              padding={0}
                              ml={2}
                              maxWidth={["90vw"]}
                              minWidth={["1000px"]}
                              height={["auto"]}
                            >
                              <ChartBar
                                isSm={isSm}
                                height={500}
                                dataChart={dataChart({
                                  labelsChart,
                                  dataChartBarFromApi,
                                  dataChartProjetadaFromApi,
                                  dataMetaDiamante,
                                  dataMetaOuro,
                                  dataChartLineFromApi,
                                  dataDescontoAplicadoFromApi,
                                })}
                                options={optionsChartBarMetas({
                                  isSm,
                                  dataDescontoAplicadoFromApi,
                                  dataChartLineFromApi,
                                })
                                }
                              />
                            </Box>
                          )}
                          {!isSm && (
                            <Bar1
                              dataChart={dataChart({
                                labelsChart,
                                dataChartBarFromApi,
                                dataChartProjetadaFromApi,
                                dataMetaDiamante,
                                dataMetaOuro,
                                dataChartLineFromApi,
                                dataDescontoAplicadoFromApi,
                              })}
                              dataChartLineFromApi={dataChartLineFromApi}
                              dataDescontoAplicadoFromApi={
                                dataDescontoAplicadoFromApi
                              }
                            />
                          )}
                          {isSm && (
                            <Box
                              px={2}
                              justifyContent={"flex-end"}
                              display={"flex"}
                              gap={1}
                              width={"100%"}
                              pb={2}
                            >
                              <Button
                                variant="outlined"
                                size="small"
                                onClick={() => onRequest()}
                              >
                                Tela Cheia
                              </Button>
                              <Button
                                variant="outlined"
                                size="small"
                                onClick={() => onExit()}
                              >
                                Sair
                              </Button>
                            </Box>
                          )}
                        </Box>
                      </div>
                    )}
                  </ReactFullscreen>

                  <Paper variant="outlined">
                    <Row>
                      <Col className="colMetasGerais1">
                        <ReactSpeedometer
                          width={
                            document.body.offsetWidth < 500
                              ? document.body.offsetWidth - 90
                              : 400
                          }
                          height={255}
                          labelFontSize={isSm ? "14px" : "10px"}
                          forceRender={true}
                          value={realizadoTotal}
                          segments={4}
                          minValue={0}
                          maxValue={
                            metaTotal > 0
                              ? metaTotal + metaTotal * 0.6
                              : realizadoTotal + realizadoTotal * 0.5
                          }
                          customSegmentStops={[
                            0,
                            metaTotal,
                            metaTotal + metaTotal * 0.15,
                            metaTotal + metaTotal * 0.3,
                            metaTotal > 0
                              ? metaTotal + metaTotal * 0.6
                              : realizadoTotal + realizadoTotal * 0.5,
                          ]}
                          segmentColors={[
                            "#c4302b",
                            "#00b14f",
                            "#FFD700",
                            "#6395ec",
                          ]}
                          currentValueText={realizadoTotal.toLocaleString(
                            "pt-br",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                              style: "currency",
                              currency: "brl",
                            }
                          )}
                          ringWidth={47}
                          valueFormat={metaTotal > 1000000 ? ".3s" : ".5s"}
                          needleTransitionDuration={3333}
                          needleTransition="easeElastic"
                          needleColor={"gray"}
                          textColor={"#000"}
                        />
                      </Col>
                      <Col
                        xsOffset={6}
                        xs={12}
                        md={6}
                        className={`colMetasGerais2`}
                      >
                        <Box
                          padding={0}
                          maxWidth={"100vw"}
                          sx={{ overflowX: ["scroll", "auto"] }}
                          height={["60vh", "auto"]}
                        >
                          <ChartBar
                            isSm={isSm}
                            useHeight={!isSm}
                            height={500}
                            dataChart={dataChartDesconto({
                              labelsChartDesconto,
                              dataDescontoParaGrafico,
                              labelsChartDescontoCod,
                              labelsChartCod,
                              dataChartLineFromApi,
                            })
                            }
                            options={optionsChartBarDesconto(isSm)}
                          />
                        </Box>
                      </Col>
                    </Row>
                  </Paper>
                </>
              )}
              <LegandaMetas />

              {dadosDisponiveis ? (
                <>
                  <Paper
                    variant="outlined"
                    sx={{
                      overflowX: "auto",
                    }}
                  >
                    <TabelaNext
                      dadosParaTabela={dataTableFromApi}
                      colunas={colunasMetasGerais2()}
                    />
                  </Paper>
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
