import {
  Box,
  Chip,
  Collapse,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { ButtonExpand } from "../ExpandMore";
import { useState } from "react";
import { formatarNumero } from "../../util/loja";
import { getTargetColor } from "../../util/functions";

export const CardBase = ({ data, idMeta }) => {
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const vendaRealizada = +data.vendaRealizada;
  const metaOuro = +data.metaOuro;
  const metaDiamante = +data.metaDiamante;
  const meta = data.metaVenda;
  const vendaProjetada = data.vendaProjetada;

  const color = getTargetColor(vendaRealizada, meta, metaOuro, metaDiamante)

  const colorProjetada = getTargetColor(vendaProjetada, meta, metaOuro, metaDiamante)

  return (
    <Stack mb={1} p={1} component={Paper} variant="outlined">
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" width="100%">
          <ButtonExpand
            expanded={expanded}
            handleExpandClick={handleExpandClick}
          />
          <Typography display="flex" alignItems="center" fontWeight="bold">
            Base {data.base}
          </Typography>
          {!expanded && <Box display={"flex"} gap={1} justifyContent={"flex-end"} ml="auto">
            <Chip
              icon={<>🚀</>}
              sx={{
                bgcolor: "#00b14f",
                paddingInline: 1,
              }}
              label={
                <Box display={"flex"} gap={1}>
                  <Typography fontWeight={"bold"}>Meta:</Typography>
                  <Typography>{formatarNumero(meta)}</Typography>
                </Box>
              }
            />

            <Chip
              sx={{
                bgcolor: colorProjetada,
              }}
              label={
                <Box display={"flex"} gap={1}>
                  <Typography fontWeight={"bold"}>Meta Projetada:</Typography>
                  <Typography>{formatarNumero(vendaProjetada)}</Typography>
                </Box>
              }
            />
          </Box>}
        </Box>
      </Box>
      <Collapse
        sx={{
          px: 1,
        }}
        in={expanded}
      >
        <Box gap={2} display={"grid"} gridTemplateColumns={"1fr 1fr 1fr"}>

          <Chip
            icon={<>🚀</>}
            sx={{
              bgcolor: "#00b14f",
            }}
            label={
              <Box display={"flex"} gap={1}>
                <Typography fontWeight={"bold"}>Meta:</Typography>
                <Typography>{formatarNumero(meta)}</Typography>
              </Box>
            }
          />
          <Chip
            sx={{
              bgcolor: color,
            }}
            label={
              <Box display={"flex"} gap={1}>
                <Typography fontWeight={"bold"}>Meta Realizada:</Typography>
                <Typography>{formatarNumero(vendaRealizada)}</Typography>
              </Box>
            }
          />
          <Chip
            sx={{
              bgcolor: colorProjetada,
            }}
            label={
              <Box display={"flex"} gap={1}>
                <Typography fontWeight={"bold"}>Meta Projetada:</Typography>
                <Typography>{formatarNumero(vendaProjetada)}</Typography>
              </Box>
            }
          />
          <Chip
            sx={{
              bgcolor: "#ffd700",
            }}
            icon={<>🥇</>}
            label={
              <Box display={"flex"} gap={1}>
                <Typography fontWeight={"bold"}>Meta Ouro:</Typography>
                <Typography>{formatarNumero(metaOuro)}</Typography>
              </Box>
            }
          />
          <Chip
            icon={<>💎</>}
            sx={{
              bgcolor: "#6395ec",
            }}
            label={
              <Box display={"flex"} gap={1}>
                <Typography fontWeight={"bold"}>Meta Diamante:</Typography>
                <Typography>{formatarNumero(metaDiamante)}</Typography>
              </Box>
            }
          />
        </Box>
      </Collapse>
    </Stack>
  );
};
