import {
  Box,
  Button,
  Collapse,
  FormControl,
  FormGroup,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import { NumericFormat } from "react-number-format";
import { forwardRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { updateStoreMonthlyTarget } from "../../util/formMetasFunctions";

const NumericFormatCustom = forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      valueIsNumericString
      thousandSeparator="."
      decimalSeparator=","
      prefix="R$ "
      placeholder="R$ 0,00"
      allowNegative={false}
    />
  );
});

export const FormEditarMetasMensal = ({ open, data, onClickSave }) => {
  const { control, getValues, setValue } = useFormContext();

  const handleSave = () => {

    const result = updateStoreMonthlyTarget(data.codLoja, getValues, setValue);
    if (result) {
      onClickSave();
    }

  };

  const handleUpdateOrderQtd = () => {
    const values = getValues(`temp.${data.codLoja}`);
    values.metaQtdPedidos = values.metaTM ? parseInt(values.metaVenda / values.metaTM) : 0;
    setValue(`temp.${data.codLoja}.metaQtdPedidos`, values.metaQtdPedidos);
  }

  const handleUpdateTM = () => {
    const values = getValues(`temp.${data.codLoja}`);
    values.metaTM = values.metaQtdPedidos ? parseInt(values.metaVenda / values.metaQtdPedidos) : 0;
    setValue(`temp.${data.codLoja}.metaTM`, values.metaTM);
  }

  return (
    <Collapse in={open}>
      <Stack
        bgcolor={"#fcfcfc"}
        mb={1}
        component={Paper}
        variant="outlined"
        p={2}
      >
        <FormGroup>
          <Box gap={2} display={"grid"} gridTemplateColumns={"1fr 1fr 1fr"}>
            <FormControl>
              <Controller
                name={`temp.${data.codLoja}.metaVenda`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{
                      background: "white",
                    }}
                    size="small"
                    label="Valor Vendas"
                    id="formatted-numberformat-input"
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                    }}
                    onBlur={handleUpdateOrderQtd}
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <Controller
                name={`temp.${data.codLoja}.metaTM`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{
                      background: "white",
                    }}
                    size="small"
                    label="Ticket Medio"
                    id="formatted-numberformat-input"
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                    }}
                    onBlur={handleUpdateOrderQtd}
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <Controller
                name={`temp.${data.codLoja}.metaQtdPedidos`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{
                      background: "white",
                    }}
                    type="number"
                    label="Qtd Pedidos"
                    size="small"
                    onBlur={handleUpdateTM}
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <Controller
                name={`temp.${data.codLoja}.metaItensPorVenda`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="number"
                    sx={{
                      background: "white",
                    }}
                    label="Item por venda"
                    size="small"
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <Controller
                name={`temp.${data.codLoja}.metaClientesCadastrados`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{
                      background: "white",
                    }}
                    type="number"
                    label="Clientes cadastrados"
                    size="small"
                  />
                )}
              />
            </FormControl>
            <FormControl disabled>
              <Controller
                name={`temp.${data.codLoja}.diasUteis`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{
                      background: "white",
                    }}
                    disabled
                    type="number"
                    label="Dias uteis"
                    size="small"
                  />
                )}
              />
            </FormControl>
          </Box>
        </FormGroup>
        <Stack justifyContent={"flex-end"} alignItems={"flex-end"} mt={2}>
          <Button
            onClick={handleSave}
            size="small"
            color="aprincipal"
            variant="contained"
          >
            Atualizar registro
          </Button>
        </Stack>
      </Stack>
    </Collapse>
  );
};
