import { api } from "./api.v2";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getData: async () => {
    return await api.get(`/targets/bonus/current-bonus`);
  },
  update: async (data) => {
    return await api.put("/targets/bonus", data);
  },
  getResults: async (targetId) => {
    return await api.get(`/targets/sales/${targetId}/results`);
  },
  getPeriodResults: async (targetId) => {
    return await api.get(`/targets/sales/${targetId}/results/by-period`);
  },
};
