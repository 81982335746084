import { createContext, useEffect, useState } from "react";
import { LoadingTable } from "../../../components/Loading/LoadingTable";
import Title from "../../../components/title";
import { PermissionsPage } from "../../../util/permissionsPage";
import { Box, Button } from "@mui/material";
import ErrorToastHandler from "../../../util/ErrorToastHandler";
import dayjs from "dayjs";
import "react-datepicker/dist/react-datepicker.css";
import { Container } from "react-bootstrap";
import premiacoes from "../../../services/premiacoes";
import metas from "../../../services/metas";
import BootstrapTable from "react-bootstrap-table-next";
import { colunasResultados, colunasResultadosPorPeriodo, rowClasses } from "./columns";
import filterFactory from "react-bootstrap-table2-filter";
import { SelectFilterMonth } from "../../../components/FilterLojaMetas/SelectMes";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

export const FormContext = createContext();
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");

// Adiciona os plugins necessários ao dayjs
dayjs.extend(utc);
dayjs.extend(timezone);

const expandRow = {
  renderer: row => {
    return (
      <div style={{ width: "100%", borderLeft: '8px solid #ff6384' }}>
        <BootstrapTable
          keyField="codFunc"
          data={row.periodos}
          columns={colunasResultadosPorPeriodo}
          bootstrap4={true}
          rowClasses={rowClasses}
        />
      </div>
    )
  },
  showExpandColumn: true,
  expandHeaderColumnRenderer: ({ isAnyExpands }) => {
    if (isAnyExpands) {
      return <KeyboardArrowUp />;
    }
    return <KeyboardArrowDown />;
  },
  expandColumnRenderer: ({ expanded }) => {
    if (expanded) {
      return <KeyboardArrowUp />;
    }
    return <KeyboardArrowDown />;
  }
};

export const ResultadosSemanal = () => {
  const [paramsConfig, setParamsConfig] = useState(null);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [metasDisponiveis, setMetasDisponiveis] = useState([]);
  const [resultadosMeta, setResultadosMeta] = useState([]);

  const formatData = (metas) => {

    const metasFormatadas = metas.map((meta) => {
      return {
        ...meta.funcionario,
        resumo: true,
        periodos: meta.periodos,
      }
    });
    console.log(metasFormatadas)

    setResultadosMeta(metasFormatadas);

  }

  function selecionarMeta(value) {
    setParamsConfig((old) => ({
      meta: value,
    }));
  }

  const getAvailableTargets = async () => {
    try {
      const response = await metas.getAvailableTargets();
      const data = response.data;
      const lastMonth = dayjs().subtract(1, 'month').format('MMM/YYYY');
      const firstMetaLastMonth = data.find(item => item.periodo?.toLowerCase() === lastMonth);
      console.log({ firstMetaLastMonth });

      setMetasDisponiveis(data);
      setParamsConfig({ meta: firstMetaLastMonth?.id });
    } catch (error) {
      new ErrorToastHandler(error).showErrorToast();
    }
  };

  const getDataPage = async () => {
    setIsLoadingData(true);
    try {

      if (!paramsConfig.meta) {
        setIsLoadingData(false);
        return;
      }
      const response = await premiacoes.getPeriodResults(paramsConfig.meta);
      formatData(response.data);
    } catch (error) {
      console.log(error);
      setResultadosMeta([]);
      new ErrorToastHandler(error).showErrorToast();
    } finally {
      setIsLoadingData(false);
    }
  };

  useEffect(() => {
    console.log({ paramsConfig });

    if (
      !!paramsConfig?.meta
    ) {
      getDataPage();
    }
  }, [paramsConfig]);

  useEffect(() => {
    getAvailableTargets();
  }, []);

  return (
    <div>
      <Title title="Resultado Semanal" />
      {
        PermissionsPage("resultadosMetasSemanal") && (
          <>
            {isLoadingData ? (
              <LoadingTable />
            ) : (
              (<Box sx={{ mt: 2 }}>
                <Box mt={1} width={"100%"}>
                  <SelectFilterMonth
                    periodoReferencia={paramsConfig?.meta}
                    periodosDisponiveis={metasDisponiveis}
                    selecionarPeriodo={selecionarMeta}
                    label="Mês"
                  />
                </Box>
                <Box mt={1} mb={4} width={"100%"}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="aprincipal"
                    onClick={() => window.print()}
                    className="nao-imprimir"
                  >Imprimir/Salvar</Button>
                </Box>
                <BootstrapTable
                  keyField="key"
                  data={resultadosMeta}
                  columns={colunasResultados}
                  bootstrap4={true}
                  rowClasses={rowClasses}
                  noDataIndication={<Container>Nenhuma Loja Disponível</Container>}
                  filter={filterFactory()}
                  expandRow={expandRow}
                />
              </Box>)
            )}
          </>
        )
      }
    </div>
  );
};
