import { forwardRef } from "react";
import { NumericFormat } from "react-number-format";

export const NumericFormatCustom = forwardRef(function NumericFormatCustom(
    props,
    ref
) {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            valueIsNumericString
            thousandSeparator="."
            decimalSeparator=","
            prefix=""
            suffix="%"
            placeholder="0,00%"
            allowNegative={false}
        />
    );
});