import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Select from "react-select";
import "./styles.css";

export const SelectFilterMonth = ({
  periodoReferencia,
  periodosDisponiveis,
  selecionarPeriodo,
  isDisabled,
  label="Período"
}) => {
  const [value, setValue] = useState({});

  useEffect(() => {
    if (!!periodoReferencia) {
      const filterPeriod = periodosDisponiveis?.find(
        (item) => +item?.id === +periodoReferencia
      );
      const formatedPeriod = {
        value: filterPeriod?.id,
        label: filterPeriod?.periodo,
      };
      setValue(formatedPeriod);
    }
  }, [periodoReferencia, periodosDisponiveis]);

  const onChageSelect = (value) => {
    setValue(value);
    selecionarPeriodo(value.value);
  };

  const options = periodosDisponiveis?.map((item) => ({
    value: item?.id,
    label: item?.periodo,
  }));

  return (
    <>
      <Box display={"grid"} alignItems={"center"} width={"100%"}>
        <Typography textAlign={"initial"} fontWeight={"bold"} component={"span"}>
          {label}
        </Typography>
        <Select
          value={value}
          onChange={onChageSelect}
          options={options}
          isDisabled={isDisabled}
          classNames={{
            control: (state) => state.isFocused && "borderIsFocus",
          }}
        />
      </Box>
    </>
  );
};
