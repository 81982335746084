import {
    Box,
    Card,
    CardActions,
    CardContent,
    Collapse,
    Stack,
    Typography,
} from "@mui/material";

import { useState } from "react";
import { FaExclamationTriangle } from "react-icons/fa";
import { ButtonExpand } from "../../ExpandMore";
import { TableMain } from "../tableMain";
import { TableLideranca } from "../tableLideranca";

export const CardStore = ({ item }) => {

    const [openStoreCollapse, setOpenStoreCollapse] = useState(false);

    return (
        <Card variant="outlined">
            <CardActions
                sx={{
                    pb: "4px",
                    pr: "16px",
                    cursor: "pointer",
                }}
                disableSpacing
                onClick={() => setOpenStoreCollapse((old) => !old)}
            >
                <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    width={"100%"}
                    alignItems={"center"}
                >
                    <Typography
                        sx={{ fontSize: 14 }}
                        color="text.primary"
                        fontWeight={"500"}
                        mb={0}
                        component={"span"}
                        s
                        gutterBottom
                    >
                        <ButtonExpand
                            handleExpandClick={() => { }}
                            expanded={openStoreCollapse}
                        />
                        {item.codLoja} - {item.sigla} (Base {item.base})
                        {item.new && <Box component='span' sx={{ color: 'red', ml: 2 }} ><FaExclamationTriangle /> Premiação não cadastrada</Box>}
                    </Typography>
                </Stack>
            </CardActions>
            {openStoreCollapse && <Collapse in={openStoreCollapse}>
                <CardContent
                    sx={{
                        pt: 0,
                        pb: "4px",
                    }}
                >
                    <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        width={"100%"}
                        alignItems={"flex-end"}
                    >
                        <Typography
                            sx={{ fontSize: 14 }}
                            color="text.secondary"
                            fontWeight={"500"}
                            component={"span"}
                            mb={0}
                            gutterBottom
                        >
                            Premiações
                        </Typography>
                    </Stack>
                    <TableMain codLoja={item.codLoja} />
                </CardContent>
                <CardContent
                    sx={{
                        pt: 0,
                        pb: "4px",
                    }}
                >
                    <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        width={"100%"}
                        alignItems={"flex-end"}
                    >
                        <Typography
                            sx={{ fontSize: 14 }}
                            color="text.secondary"
                            fontWeight={"500"}
                            component={"span"}
                            mb={0}
                            gutterBottom
                        >
                            Lideranças
                        </Typography>
                    </Stack>
                    <TableLideranca base={item.base} name={`${item.codLoja}.lideranca`} />
                </CardContent>
            </Collapse>}
        </Card>
    );
};
