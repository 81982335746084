import { red } from "@mui/material/colors";

export const getTargetColor = (realizado, meta, metaOuro, metaDiamante) => {
  const color =
    realizado >= meta
      ? "#00b14f"
      : realizado >= metaOuro
      ? "#ffd700"
      : realizado >= metaDiamante
      ? "#6395ec"
      : red[300];

  return color;
};
