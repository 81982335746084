import { createContext, useEffect, useState } from "react";
import { LoadingTable } from "../../../components/Loading/LoadingTable";
import Title from "../../../components/title";
import { PermissionsPage } from "../../../util/permissionsPage";
import metas from "../../../services/metas";
import { Box, Button, Stack } from "@mui/material";
import ErrorToastHandler from "../../../util/ErrorToastHandler";
import dayjs from "dayjs";
import { FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import "react-datepicker/dist/react-datepicker.css";
import { Spinner } from "react-bootstrap";
import premiacoes from "../../../services/premiacoes";
import { CardStore } from "../../../components/parametrosBonificacao/cardStore";

export const FormContext = createContext();
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");

// Adiciona os plugins necessários ao dayjs
dayjs.extend(utc);
dayjs.extend(timezone);

const tiposMeta = [
  { label: '90% da meta', value: 1 },
  { label: 'Meta', value: 2 },
  { label: 'Ouro', value: 3 },
  { label: 'Diamante', value: 4 },
]

export const ParametrosBonificacao = () => {
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const methods = useForm();
  const { reset, handleSubmit } = methods;
  const [periodsSelects, setPeriodsSelects] = useState([]);
  const [funcionariosNaoSelecionados, setFuncionariosNaoSelecionados] =
    useState([]);
  const [dataPage, setDataPage] = useState([]);

  const formatData = (data) => {
    const dataReset = {}
    data.forEach((item) => {
      const bonusAtual = item.bonusAtual || [];
      const bonusAtualReset = {};
      bonusAtual.forEach((item) => {
        const tipoMeta = tiposMeta.find(tipo => tipo.label === item.meta);
        if (!tipoMeta) {
          return;
        }
        bonusAtualReset[tipoMeta.value] = {
          ...item, description: tipoMeta.label
        };
      });

      tiposMeta.forEach((tipo) => {
        if (!bonusAtualReset[tipo.value]) {
          bonusAtualReset[tipo.value] = {
            base: item.base,
            codLoja: item.codLoja,
            gerente: 0,
            lider: 0,
            meta: tipo.label,
            vendedor: 0,
            description: tipo.label
          };
        }
      });
      dataReset[item.codLoja] = {
        ...item,
        bonusAtual: bonusAtualReset,
      };
    });

    setDataPage(dataReset);
    reset({
      ...dataReset,
      temp: dataReset
    });
  }

  const getDataPage = async () => {
    setIsLoadingData(true);
    try {
      const response = await premiacoes.getData();
      formatData(response.data.lojas);
      console.log(response.data);

      setFuncionariosNaoSelecionados(
        response.data.novosFuncionarios
      );
    } catch (error) {
      console.log(error);

      new ErrorToastHandler(error).showErrorToast();
    } finally {
      setIsLoadingData(false);
    }
  };

  const onSubmit = async (data) => {
    delete data.temp;
    const formDataObj = { ...data }
    const formData = Object.values(formDataObj).map((item) => {
      const bonusAtual = Object.values(item.bonusAtual);
      return {
        ...item,
        bonusAtual
      }
    });

    setIsSubmitting(true);

    try {
      const response = await premiacoes.update(formData);
      toast.success(response.data.msg, {
        duration: 6000,
      });
    } catch (error) {
      new ErrorToastHandler(error).showErrorToast();
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    getDataPage();
  }, []);

  return (
    <FormContext.Provider
      value={{
        funcionariosNaoSelecionados,
        setFuncionariosNaoSelecionados,
        setDataPage,
        dataPage,
        periodsSelects,
        setPeriodsSelects,
        isLoadingData,
      }}
    >
      <Title title="Premiações" />
      <FormProvider {...methods}>
        {PermissionsPage("cParametrosBonificacao") && (
          <>
            {isLoadingData ? (
              <LoadingTable />
            ) : (
              (<Box onSubmit={handleSubmit(onSubmit)} sx={{ mt: 2 }} component='form'>
                <Stack gap={2}>
                  {Object.values(dataPage).map((item) => (
                    <CardStore key={item.codLoja} item={item} />
                  ))}
                </Stack>
                <Stack py={2} direction={"row"} justifyContent={"flex-end"}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="aprincipal"
                    disabled={isSubmitting}
                  >
                    Salvar e enviar {isSubmitting && <Spinner animation="border" style={{ marginLeft: '16px' }} />}
                  </Button>
                </Stack>
              </Box>)
            )}
          </>
        )}
      </FormProvider>
    </FormContext.Provider>
  );
};
