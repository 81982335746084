const getEmployeeMonthlyTarget = (codLoja, storeMonthlyValues, getValues) => {
  const values = getValues(`${codLoja}.funcionarios`);

  const employees = Object.values(values);
  const specialEmployees = employees.filter(
    (employee) => employee.metaEspecial
  );
  const nonSpecialEmployees = employees.filter(
    (employee) => !employee.metaEspecial
  );

  const totalSpecialValue = specialEmployees.reduce(
    (acc, employee) => acc + employee.metaVenda,
    0
  );
  const totalSpecialQtd = specialEmployees.reduce(
    (acc, employee) => acc + employee.metaQtdPedidos,
    0
  );
  const totalSpecialClientesCadastrados = specialEmployees.reduce(
    (acc, employee) => acc + employee.metaClientesCadastrados,
    0
  );

  const remainingValue = storeMonthlyValues.metaVenda - totalSpecialValue;
  const remainingQtd = storeMonthlyValues.metaQtdPedidos - totalSpecialQtd;
  const remainingClientesCadastrados =
    storeMonthlyValues.metaClientesCadastrados -
    totalSpecialClientesCadastrados;

  const employeeValueByPeriod = Math.ceil(
    remainingValue / nonSpecialEmployees.length
  );
  const employeeQtdByPeriod = Math.ceil(
    remainingQtd / nonSpecialEmployees.length
  );
  const employeeClientesCadastradosByPeriod = Math.ceil(
    remainingClientesCadastrados / nonSpecialEmployees.length
  );

  const newEmployees = {};
  employees.forEach((employee) => {
    if (employee.metaEspecial) {
      newEmployees[employee.codFunc] = employee;
      return;
    }

    newEmployees[employee.codFunc] = {
      ...employee,
      ...storeMonthlyValues,
      metaItensPorVenda: storeMonthlyValues.metaItensPorVenda,
      metaTM: storeMonthlyValues.metaTM,
      metaVenda: Math.max(0, employeeValueByPeriod),
      metaQtdPedidos: Math.max(0, employeeQtdByPeriod),
      metaClientesCadastrados: Math.max(0, employeeClientesCadastradosByPeriod),
    };
  });

  return newEmployees;
};

const getStoreWeeklyTarget = (codLoja, monthlyValues, getValues) => {
  const periodValues = getValues(`${codLoja}.periodos`);
  const periods = Object.values(periodValues);

  const storeValue = monthlyValues.metaVenda;
  const storeQtd = monthlyValues.metaQtdPedidos;

  const specialPeriods = periods.filter((period) => period?.loja?.metaEspecial);
  const nonSpecialPeriods = periods.filter(
    (period) => !period?.loja?.metaEspecial
  );

  const totalSpecialValue = specialPeriods.reduce(
    (acc, period) => acc + Number(period.loja.metaVenda),
    0
  );
  const totalSpecialQtd = specialPeriods.reduce(
    (acc, period) => acc + Number(period.loja.metaQtdPedidos),
    0
  );

  const totalSpecialClientesCadastrados = specialPeriods.reduce(
    (acc, period) => acc + Number(period.loja.metaClientesCadastrados),
    0
  );

  const remainingValue = storeValue - totalSpecialValue;
  const remainingQtd = storeQtd - totalSpecialQtd;
  const remainingClientesCadastrados =
    monthlyValues.metaClientesCadastrados - totalSpecialClientesCadastrados;

  const storeValueByPeriod = Math.ceil(
    remainingValue / nonSpecialPeriods.length
  );
  const storeQtdByPeriod = Math.ceil(remainingQtd / nonSpecialPeriods.length);
  const storeClientesCadastradosByPeriod = Math.ceil(
    remainingClientesCadastrados / nonSpecialPeriods.length
  );

  const newPeriods = {};
  periods.forEach((period) => {
    if (period.loja.metaEspecial) {
      newPeriods[period.periodKey] = period;
      return;
    }

    newPeriods[period.periodKey] = {
      ...period,
      loja: {
        ...period.loja,
        metaItensPorVenda: monthlyValues.metaItensPorVenda,
        metaTM: monthlyValues.metaTM,
        metaVenda: Math.max(0, storeValueByPeriod),
        metaQtdPedidos: Math.max(0, storeQtdByPeriod),
        metaClientesCadastrados: Math.max(0, storeClientesCadastradosByPeriod),
      },
    };
  });
  return newPeriods;
};

const getStoreMonthlyTarget = (codLoja, getValues) => {
  const values = getValues(`temp.${codLoja}`);
  const employees = getEmployeeMonthlyTarget(codLoja, values, getValues);

  return { ...values, funcionarios: employees };
};

const calculateEmployeeMonthlyTarget = (codLoja, getValues, codFunc = null) => {
  const employeesValues = getValues(`${codLoja}.funcionarios`);
  const storeMonthlyValues = getValues(`${codLoja}`);

  if (codFunc) {
    const values = getValues(`temp.${codLoja}.funcionarios.${codFunc}`);
    employeesValues[codFunc] = values;
  }

  const employees = Object.values(employeesValues);
  const specialEmployees = employees.filter(
    (employee) => employee.metaEspecial
  );
  const nonSpecialEmployees = employees.filter(
    (employee) => !employee.metaEspecial
  );

  const totalSpecialValue = specialEmployees.reduce(
    (acc, employee) => acc + employee.metaVenda,
    0
  );
  const totalSpecialQtd = specialEmployees.reduce(
    (acc, employee) => acc + employee.metaQtdPedidos,
    0
  );

  const totalSpecialClientesCadastrados = specialEmployees.reduce(
    (acc, employee) => acc + employee.metaClientesCadastrados,
    0
  );

  const remainingValue = storeMonthlyValues.metaVenda - totalSpecialValue;
  const remainingQtd = storeMonthlyValues.metaQtdPedidos - totalSpecialQtd;
  const remainingClientesCadastrados =
    storeMonthlyValues.metaClientesCadastrados -
    totalSpecialClientesCadastrados;

  const valueByEmployee = Math.ceil(
    remainingValue / nonSpecialEmployees.length
  );
  const employeeQtdByEmployee = Math.ceil(
    remainingQtd / nonSpecialEmployees.length
  );
  const employeeClientesCadastradosByEmployee = Math.ceil(
    remainingClientesCadastrados / nonSpecialEmployees.length
  );

  const newEmployees = {};
  employees.forEach((employee) => {
    if (employee.metaEspecial) {
      newEmployees[employee.codFunc] = employee;
      return;
    }

    newEmployees[employee.codFunc] = {
      ...employee,
      metaTM: storeMonthlyValues.metaTM,
      metaItensPorVenda: storeMonthlyValues.metaItensPorVenda,
      metaVenda: Math.max(0, valueByEmployee),
      metaQtdPedidos: Math.max(0, employeeQtdByEmployee),
      metaClientesCadastrados: Math.max(
        0,
        employeeClientesCadastradosByEmployee
      ),
    };
  });

  return newEmployees;
};

const calculateEmployeeWeeklyTarget = (codLoja, getValues) => {
  const employees = getValues(`${codLoja}.funcionarios`);
  const periods = getValues(`${codLoja}.periodos`);

  const periodValues = Object.values(periods);
  const employeeKeys = Object.keys(employees); // Lista de todos os funcionários da loja

  const newPeriods = {};

  periodValues.forEach((period) => {
    const funcionariosAtualizados = { ...period.funcionarios };

    employeeKeys.forEach((codFunc) => {
      const employeeValues = employees[codFunc];

      if (!employeeValues) return; // Se o funcionário não existir, pula

      // Identificar se o funcionário tem metaEspecial neste período
      const isSpecial = period.funcionarios?.[codFunc]?.metaEspecial;

      if (isSpecial) {
        // Se for especial, mantém como está
        return;
      }

      // Filtrar períodos especiais e não especiais para este funcionário
      const specialPeriods = periodValues.filter(
        (p) => p.funcionarios?.[codFunc]?.metaEspecial
      );
      const nonSpecialPeriods = periodValues.filter(
        (p) => !p.funcionarios?.[codFunc]?.metaEspecial
      );

      // Cálculo de metas nos períodos especiais
      const totalSpecialValue = specialPeriods.reduce(
        (acc, p) => acc + Number(p.funcionarios[codFunc]?.metaVenda || 0),
        0
      );
      const totalSpecialQtd = specialPeriods.reduce(
        (acc, p) => acc + Number(p.funcionarios[codFunc]?.metaQtdPedidos || 0),
        0
      );
      const totalSpecialClientesCadastrados = specialPeriods.reduce(
        (acc, p) =>
          acc + Number(p.funcionarios[codFunc]?.metaClientesCadastrados || 0),
        0
      );

      // Definição das metas restantes
      const remainingValue = employeeValues.metaVenda - totalSpecialValue;
      const remainingQtd = employeeValues.metaQtdPedidos - totalSpecialQtd;
      const remainingClientesCadastrados =
        employeeValues.metaClientesCadastrados -
        totalSpecialClientesCadastrados;

      // Evita divisão por zero
      const numNonSpecialPeriods = nonSpecialPeriods.length || 1;

      const valueByPeriod = Math.ceil(remainingValue / numNonSpecialPeriods);
      const qtdByPeriod = Math.ceil(remainingQtd / numNonSpecialPeriods);
      const clientesCadastradosByPeriod = Math.ceil(
        remainingClientesCadastrados / numNonSpecialPeriods
      );

      // Atualizar os valores no funcionário
      funcionariosAtualizados[codFunc] = {
        ...period.funcionarios[codFunc], // Mantém os dados existentes
        metaTM: employeeValues.metaTM,
        metaItensPorVenda: employeeValues.metaItensPorVenda,
        metaVenda: Math.max(0, valueByPeriod),
        metaQtdPedidos: Math.max(0, qtdByPeriod),
        metaClientesCadastrados: Math.max(0, clientesCadastradosByPeriod),
      };
    });

    newPeriods[period.periodKey] = {
      ...period,
      funcionarios: funcionariosAtualizados,
    };
  });

  return newPeriods;
};

export const updateStoreMonthlyTarget = (codLoja, getValues, setValues) => {
  try {
    const monthlyValues = getStoreMonthlyTarget(codLoja, getValues);
    const weeklyTarget = getStoreWeeklyTarget(
      codLoja,
      monthlyValues,
      getValues
    );

    const newValues = {
      ...monthlyValues,
      periodos: weeklyTarget,
    };

    setValues(`${codLoja}`, newValues);
    setValues(`temp.${codLoja}`, newValues);

    const newWeeklyValues = calculateEmployeeWeeklyTarget(codLoja, getValues);
    setValues(`${codLoja}.periodos`, newWeeklyValues);
    setValues(`temp.${codLoja}.periodos`, newWeeklyValues);
    return true;
  } catch (error) {
    return false;
  }
};

export const updateStoreWeeklyTarget = (
  codLoja,
  periodKey,
  getValues,
  setValues
) => {
  try {
    setValues(`${codLoja}.periodos.${periodKey}`, {
      ...getValues(`temp.${codLoja}.periodos.${periodKey}`),
    });
    const monthlyValues = getValues(`${codLoja}`);
    const weeklyTarget = getStoreWeeklyTarget(
      codLoja,
      monthlyValues,
      getValues
    );

    const newValues = {
      ...monthlyValues,
      periodos: weeklyTarget,
    };

    setValues(`${codLoja}`, newValues);
    setValues(`temp.${codLoja}`, newValues);

    const newWeeklyValues = calculateEmployeeWeeklyTarget(codLoja, getValues);
    setValues(`${codLoja}.periodos`, newWeeklyValues);
    setValues(`temp.${codLoja}.periodos`, newWeeklyValues);
    return true;
  } catch (error) {
    return false;
  }
};

export const updateEmployeeMonthlyTarget = (
  codLoja,
  codFunc,
  getValues,
  setValues
) => {
  try {
    const newValues = calculateEmployeeMonthlyTarget(
      codLoja,
      getValues,
      codFunc
    );

    setValues(`${codLoja}.funcionarios`, newValues);
    setValues(`temp.${codLoja}.funcionarios`, newValues);

    const newWeeklyValues = calculateEmployeeWeeklyTarget(codLoja, getValues);
    setValues(`${codLoja}.periodos`, newWeeklyValues);
    setValues(`temp.${codLoja}.periodos`, newWeeklyValues);
    return true;
  } catch (error) {
    return false;
  }
};

export const updateEmployeeWeeklyTarget = (
  codLoja,
  codFunc,
  periodKey,
  getValues,
  setValues
) => {
  try {
    setValues(`${codLoja}.periodos.${periodKey}.funcionarios.${codFunc}`, {
      ...getValues(
        `temp.${codLoja}.periodos.${periodKey}.funcionarios.${codFunc}`
      ),
    });
    const newWeeklyValues = calculateEmployeeWeeklyTarget(codLoja, getValues);
    setValues(`${codLoja}.periodos`, newWeeklyValues);
    setValues(`temp.${codLoja}.periodos`, newWeeklyValues);
    return true;
  } catch (error) {
    return false;
  }
};

export const addEmployeeToStore = (codLoja, newFunc, getValues, setValues) => {
  const newFuncValues = {
    codLoja: newFunc.codLoja,
    loja: "",
    base: newFunc.base,
    nomeFunc: newFunc.nomeFunc,
    codFunc: newFunc.codFunc,
    metaVenda: 0,
    metaTM: 0,
    metaQtdPedidos: 0,
    metaItensPorVenda: 0,
    metaClientesCadastrados: 0,
    diasUteis: 0,
    diasRemovidos: getValues(`${codLoja}.diasRemovidos`) || [],
    metaEspecial: 0,
  };
  setValues(`${codLoja}.funcionarios.${newFunc.codFunc}`, newFuncValues);
  setValues(`temp.${codLoja}.funcionarios.${newFunc.codFunc}`, newFuncValues);

  const periodValues = getValues(`${codLoja}.periodos`);
  const periods = Object.values(periodValues);

  const newPeriods = {};
  periods.forEach((period) => {
    newPeriods[period.periodKey] = {
      ...period,
      funcionarios: {
        ...period.funcionarios,
        [newFunc.codFunc]: newFuncValues,
      },
    };
  });

  setValues(`${codLoja}.periodos`, newPeriods);
  setValues(`temp.${codLoja}.periodos`, newPeriods);

  const newValues = calculateEmployeeMonthlyTarget(codLoja, getValues);

  setValues(`${codLoja}.funcionarios`, newValues);
  setValues(`temp.${codLoja}.funcionarios`, newValues);

  const newWeeklyValues = calculateEmployeeWeeklyTarget(codLoja, getValues);
  setValues(`${codLoja}.periodos`, newWeeklyValues);
  setValues(`temp.${codLoja}.periodos`, newWeeklyValues);
  return true;
};

export const removeEmployeeFromTarget = (
  codLoja,
  codFunc,
  getValues,
  setValues
) => {
  const employees = getValues(`${codLoja}.funcionarios`);
  delete employees[codFunc];
  setValues(`${codLoja}.funcionarios`, employees);
  setValues(`temp.${codLoja}.funcionarios`, employees);

  const periodValues = getValues(`${codLoja}.periodos`);
  const periods = Object.values(periodValues);

  const newPeriods = {};
  periods.forEach((period) => {
    const { [codFunc]: _, ...remainingEmployees } = period.funcionarios;
    newPeriods[period.periodKey] = {
      ...period,
      funcionarios: remainingEmployees,
    };
  });

  setValues(`${codLoja}.periodos`, newPeriods);
  setValues(`temp.${codLoja}.periodos`, newPeriods);

  const newValues = calculateEmployeeMonthlyTarget(codLoja, getValues);

  setValues(`${codLoja}.funcionarios`, newValues);
  setValues(`temp.${codLoja}.funcionarios`, newValues);

  const newWeeklyValues = calculateEmployeeWeeklyTarget(codLoja, getValues);
  setValues(`${codLoja}.periodos`, newWeeklyValues);
  setValues(`temp.${codLoja}.periodos`, newWeeklyValues);
  return true;
};

export const removePeriodFromTarget = (periodKey, getValues, setValues) => {
  const allStores = getValues();
  Object.keys(allStores)
    .filter((itemKey) => itemKey !== "temp")
    .forEach((codLoja) => {
      const periods = getValues(`${codLoja}.periodos`);
      delete periods[`${periodKey}`];
      setValues(`${codLoja}.periodos`, periods);
      setValues(`temp.${codLoja}.periodos`, periods);

      const monthlyValues = getValues(`${codLoja}`);
      const weeklyTarget = getStoreWeeklyTarget(
        codLoja,
        monthlyValues,
        getValues
      );

      const newValues = {
        ...monthlyValues,
        periodos: weeklyTarget,
      };
      setValues(`${codLoja}`, newValues);
      setValues(`temp.${codLoja}`, newValues);

      const newWeeklyValues = calculateEmployeeWeeklyTarget(codLoja, getValues);

      setValues(`${codLoja}.periodos`, newWeeklyValues);
      setValues(`temp.${codLoja}.periodos`, newWeeklyValues);
    });
  return true;
};

export const addPeriodToTarget = (newPeriod, getValues, setValues) => {
  const allStores = getValues();
  Object.keys(allStores)
    .filter((itemKey) => itemKey !== "temp")
    .forEach((codLoja) => {
      const periods = getValues(`${codLoja}.periodos`);
      const zeroedPeriod = {
        ...newPeriod,
        loja: {
          metaVenda: 0,
          metaTM: 0,
          metaQtdPedidos: 0,
          metaItensPorVenda: 0,
          metaEspecial: 0,
          metaClientesCadastrados: 0,
        },
        funcionarios: Object.keys(
          periods[Object.keys(periods)[0]].funcionarios
        ).reduce((acc, codFunc) => {
          acc[codFunc] = {
            ...periods[Object.keys(periods)[0]].funcionarios[codFunc],
            metaVenda: 0,
            metaTM: 0,
            metaQtdPedidos: 0,
            metaItensPorVenda: 0,
            metaClientesCadastrados: 0,
          };
          return acc;
        }, {}),
      };
      periods[newPeriod.periodKey] = zeroedPeriod;
      setValues(`${codLoja}.periodos`, periods);
      setValues(`temp.${codLoja}.periodos`, periods);

      const monthlyValues = getValues(`${codLoja}`);
      const weeklyTarget = getStoreWeeklyTarget(
        codLoja,
        monthlyValues,
        getValues
      );

      const newValues = {
        ...monthlyValues,
        periodos: weeklyTarget,
      };

      const newWeeklyValues = calculateEmployeeWeeklyTarget(codLoja, getValues);

      setValues(`${codLoja}`, newValues);
      setValues(`temp.${codLoja}`, newValues);
      setValues(`${codLoja}.periodos`, newWeeklyValues);
      setValues(`temp.${codLoja}.periodos`, newWeeklyValues);
    });
  return true;
};
