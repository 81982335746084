import { useEffect, useState } from "react";
import { arrayBases } from "../util/loja";
import { useUserData } from "./useUserData";

export const useAccessFilter = (callback = () => {}) => {
  const { userData } = useUserData();
  const accessLevel = userData?.nivelAcesso;
  const accessBase = userData?.baseAcesso;
  const [base, setBase] = useState();
  const [basesDisponiveis, setBasesDisponiveis] = useState([]);
  const [basesSelecionadas, setBasesSelecionadas] = useState([]);
  const [lojasDisponiveis, setLojasDisponiveis] = useState([]);
  const [lojasSelecionadas, setLojasSelecionadas] = useState([]);

  const buscarNivelAcesso = async () => {
    if (accessLevel) {
      setBasesDisponiveis(arrayBases(accessBase));
      if (accessBase.length > 0) {
        setBase(accessBase[0]);
        setBasesSelecionadas([accessBase[0]]);
        if (callback) {
          callback(accessBase[0]);
        }
      }
    }
  };

  const filtrarBases = (codBase) => {
    let basesSelecionadasTemp = basesSelecionadas.filter((a) => true);
    if (basesSelecionadasTemp.filter((a) => a == codBase).length > 0) {
      basesSelecionadasTemp = basesSelecionadasTemp.filter((a) => a != codBase);
    } else {
      basesSelecionadasTemp = codBase;
    }
    setBase(basesSelecionadasTemp);
    setBasesSelecionadas([basesSelecionadasTemp]);
  };

  function filtrarPorLoja(loja) {}

  useEffect(() => {
    buscarNivelAcesso();
  }, [userData]);

  return {
    base,
    basesDisponiveis,
    basesSelecionadas,
    lojasDisponiveis,
    lojasSelecionadas,
    setBasesSelecionadas,
    filtrarBases,
    filtrarPorLoja,
    setLojasDisponiveis,
    setLojasSelecionadas,
  };
};
