import { Box, Grid, Typography } from "@mui/material"
import { formatCurrency, formatDecimal } from "../../../../../util/number"
import { Pie } from "react-chartjs-2";

export const TabCashbackItem = ({ title, data }) => {

    return <Grid item xs={12} md={6} container gap={2}>

        <Typography
            variant="h6"
            fontWeight={"normal"}
            textAlign="center"
            width="100%"
        >
            {title}
        </Typography>
        <Box
            sx={{
                display: ["grid", "grid", "flex"],
                width: "100%",
                padding: '8px'
            }}
        >
            <Pie
                data={{
                    labels: ["Disponível", "Utilizado", "Vencido"],
                    datasets: [
                        {
                            label: "",
                            data: [
                                data?.currentYear?.vlrTotalDisponivel,
                                data?.currentYear?.vlrTotalUtilizado,
                                data?.currentYear?.vlrTotalVencido,
                            ],
                            backgroundColor: [
                                "rgba(255, 206, 86, 0.2)",
                                "rgba(75, 192, 192, 0.2)",
                                "rgba(153, 102, 255, 0.2)",
                                "rgba(255, 159, 64, 0.2)",
                            ],
                            borderColor: [
                                "rgba(255, 206, 86, 1)",
                                "rgba(75, 192, 192, 1)",
                                "rgba(153, 102, 255, 1)",
                                "rgba(255, 159, 64, 1)",
                            ],
                            borderWidth: 1,
                        },
                    ],
                }}
                options={{
                    plugins: {
                        datalabels: {
                            formatter: (value, ctx) => {
                                return formatCurrency(value);
                            },
                            color: "gray", // Cor dos rótulos
                            font: {
                                weight: "bold",
                            },
                        },
                    },
                    tooltips: {
                        callbacks: {
                            label: (tooltipItem, data) => {
                                const dataset = data.datasets[tooltipItem.datasetIndex];
                                const currentValue = dataset.data[tooltipItem.index];
                                return (
                                    data.labels[tooltipItem.index] +
                                    ": " +
                                    formatCurrency(currentValue)
                                );
                            },
                        },
                    },
                    legend: {
                        display: true,
                        position: "left",
                    },
                }}
            />
        </Box>
    </Grid>
}