import { Box, Tab, Tabs, Typography } from "@mui/material";
import { CustomTabPanel } from "../componentsAnaliseClientes/CustomTabPanel";
import { useContext, useEffect, useState } from "react";
import { a11yProps } from "../../util/a11yProps";
import dayjs from "dayjs";
import { FormContext } from "../../pages/financeiro/metas/cadastro-metas";
import { FormExpandableRowsPeriodo } from "./FormExpandableRowsPeriodo";
import { updateEmployeeWeeklyTarget } from "../../util/formMetasFunctions";
import { useFormContext } from "react-hook-form";

export const FormRowFuncionarioPeriodo = ({ data, expanded, handleExpandClick, codLoja, codFunc }) => {

  const { getValues, setValue } = useFormContext();
  const [valueTab, setValueTab] = useState(0);
  const { periodsSelects } = useContext(FormContext);
  const handleChange = (event, newValue) => {
    setValueTab(newValue);
  };

  useEffect(() => {
    if (!!expanded) {
      handleChange(null, 0);
    }
  }, [expanded]);

  const handleSave = (key) => {
    updateEmployeeWeeklyTarget(codLoja, codFunc, key, getValues, setValue)
    if (valueTab + 1 === Object.values(periodsSelects).length) {
      setValueTab(0);
      handleExpandClick();
    } else {
      setValueTab((old) => old + 1);
    }
  };

  const handlePreviousTab = () => {
    if (valueTab === 0) {
      return;
    }
    setValueTab((old) => old - 1);
  }

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          py: 0.5,
        }}
      >
        <Tabs
          value={valueTab}
          // onChange={handleChange}
          aria-label="tabs periods"
          orientation="vertical"
          variant="scrollable"
        >
          {periodsSelects.map((item, index) => (
            <Tab
              sx={{
                borderRight: 1,
                borderColor: "divider",
              }}
              key={index}
              label={`${dayjs(item.start).format("DD/MM")} - ${dayjs(
                item.end
              ).format("DD/MM")}`}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
        <Box flex={1} px={2}>
          {data.map((itemFunc, index) => (
            <CustomTabPanel
              key={itemFunc?.start}
              value={valueTab}
              index={index}
            >
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                fontWeight={"500"}
                component={"span"}
                mb={0}
                gutterBottom
              >
                Semana {valueTab + 1} -
                {`${dayjs(itemFunc?.start).format(
                  "DD/MM/YYYY"
                )} - ${dayjs(itemFunc?.end).format(
                  "DD/MM/YYYY"
                )}`}
              </Typography>
              <FormExpandableRowsPeriodo
                data={itemFunc}
                valueTab={valueTab}
                handleSave={() => handleSave(itemFunc.periodKey)}
                codLoja={codLoja}
                isLastPeriod={valueTab + 1 === Object.values(periodsSelects).length}
                isFirstPeriod={valueTab === 0}
                handlePreviousTab={handlePreviousTab}
              />
            </CustomTabPanel>
          ))}
        </Box>
      </Box>
    </>
  );
};
