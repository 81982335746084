import {
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useState } from "react";
import { CollapseTipoMeta } from "../collapseTipoMeta";
import { FaEdit } from "react-icons/fa";

export const TableMain = ({ codLoja, open }) => {
  console.log({ open });

  const { watch } = useFormContext();

  const data = watch(`${codLoja}`);

  return (
    <TableContainer
      component={Paper}
      variant="outlined"
      sx={{
        mt: 0.5,
        mb: 1,
      }}
    >
      <Table size="small" aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Vendedor</TableCell>
            <TableCell>Líder</TableCell>
            <TableCell>Gerente</TableCell>
            <TableCell>Bônus meta loja</TableCell>
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(data.bonusAtual).map(key => {
            const item = data.bonusAtual[key];
            return <Row item={item} bonusKey={key} />
          }
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const Row = ({ item, bonusKey }) => {
  const [openEdit, setOpenEdit] = useState(false);

  const handleExpandClick = () => {
    setOpenEdit(!openEdit);
  }

  return (
    <>
      <TableRow>
        <TableCell>{item.meta}</TableCell>
        <TableCell>{item.vendedor}</TableCell>
        <TableCell>{item.lider}</TableCell>
        <TableCell>{item.gerente}</TableCell>
        <TableCell>{item.bonus_meta_loja}</TableCell>

        <TableCell>
          <IconButton onClick={handleExpandClick} size="small">
            <FaEdit />
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse
            in={openEdit}
            timeout="auto"
          >
            <CollapseTipoMeta
              codLoja={item.codLoja}
              expanded={openEdit}
              handleExpandClick={handleExpandClick}
              bonusKey={bonusKey}
              handleSave={handleExpandClick}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}