import { createContext, useEffect, useRef, useState } from "react";
import { LoadingTable } from "../../../components/Loading/LoadingTable";
import Title from "../../../components/title";
import { PermissionsPage } from "../../../util/permissionsPage";
import metas from "../../../services/metas";
import { Box, Button, Stack } from "@mui/material";
import ErrorToastHandler from "../../../util/ErrorToastHandler";
import dayjs from "dayjs";
import { FormProvider, useForm } from "react-hook-form";
import { DateRages } from "../../../components/componentsCadastroMetas/DateRanges";
import { CardStore } from "../../../components/componentsCadastroMetas/CardStore";
import toast from "react-hot-toast";
import { useParams, useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { Spinner } from "react-bootstrap";

export const FormContext = createContext();
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");

// Adiciona os plugins necessários ao dayjs
dayjs.extend(utc);
dayjs.extend(timezone);

function getWeeksInMonth(year, month, qtdPeriods) {
  const weeks = [];
  const firstDay = dayjs(`${year}-${month}-01`);
  const lastDay = firstDay.endOf("month");

  let currentDay = firstDay;
  let currentWeekEnd = currentDay.add(6, "day");

  const daysInMonth = lastDay.date();
  const periodLength = Math.floor(daysInMonth / qtdPeriods);
  const remainderDays = daysInMonth % qtdPeriods;

  for (let i = 0; i < qtdPeriods - 1; i++) {
    const extraDay = i < remainderDays ? 1 : 0;
    currentWeekEnd = currentDay.add(periodLength + extraDay - 1, "day");
    weeks.push({
      start: currentDay.format("YYYY-MM-DD"),
      end: currentWeekEnd.format("YYYY-MM-DD"),
    });

    currentDay = currentWeekEnd.add(1, "day");
    currentWeekEnd = currentDay.add(6, "day");
  }

  weeks.push({
    start: currentDay.format("YYYY-MM-DD"),
    end: lastDay.format("YYYY-MM-DD"),
  });

  return weeks;
}

const getMonthPeriods = (qtdPeriod) => {
  const currentDate = dayjs(); // Obtém a data atual

  const currentYear = currentDate.year(); // Obtém o ano atual
  const currentMonth = currentDate.month() + 1;

  const periods = getWeeksInMonth(currentYear, currentMonth, qtdPeriod);

  return periods;
};

const formatCurrentPeriods = (periods) => {
  return periods.map((item) => ({
    ...item,
    periodKey: item.periodKey,
    start: item.dataInicio.split("T")[0],
    end: item.dataFim.split("T")[0],
  }))
}

export const CadastroMetas = () => {
  const history = useHistory();
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [currentCollapsedStore, setCurrentCollapsedStore] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [renderizarLojas, setRenderizarLojas] = useState(false);
  const methods = useForm();
  const { reset, handleSubmit, getValues } = methods;
  const params = useParams();
  const [periodsSelects, setPeriodsSelects] = useState([]);
  const [funcionariosNaoSelecionados, setFuncionariosNaoSelecionados] =
    useState([]);
  const [dataPage, setDataPage] = useState([]);

  const formatterDataPage = (data, currentPeriods, isEdit) => {

    const periods = isEdit ? formatCurrentPeriods(currentPeriods) : getMonthPeriods(currentPeriods?.length || 1);
    const storePeriods = periods.map((item) => {
      if (isEdit) {
        return item
      }
      const periodKey = Math.random().toString(36).substring(2, 12);
      return {
        ...item,
        periodKey
      }
    });

    const dataReset = {};

    data.forEach((store) => {
      if (isEdit) {
        store.diasRemovidos = Array.isArray(store.diasRemovidos) ? store.diasRemovidos.map(item => dayjs(item, "YYYYMMDD").toDate()) : [];
        const periodsTemp = {};
        const currentStorePeriods = [...store.periodos];
        currentStorePeriods.forEach((itemPeriod) => {
          const funcionariosCurrentPeriod = {};
          itemPeriod.funcionarios.forEach((itemFuncCurrentPeriod) => {
            funcionariosCurrentPeriod[itemFuncCurrentPeriod.codFunc] = {
              ...itemFuncCurrentPeriod,
            }
          })
          periodsTemp[itemPeriod.periodKey] = {
            ...itemPeriod,
            periodKey: itemPeriod.periodKey.toString(),
            funcionarios: funcionariosCurrentPeriod
          }
        })
        store.periodos = periodsTemp
      } else {
        const currentStorePeriods = [...store.periodos];

        store.diasRemovidos = [];
        store.periodos = {};

        storePeriods.forEach((item, index) => {
          const valuesPeriod = currentStorePeriods.find((itemPeriod, indexPeriod) => index === indexPeriod)
          const funcionariosCurrentPeriod = {};
          valuesPeriod.funcionarios.forEach((itemFuncCurrentPeriod) => {
            funcionariosCurrentPeriod[itemFuncCurrentPeriod.codFunc] = {
              ...itemFuncCurrentPeriod,
            }
          })
          store.periodos[item.periodKey] = {
            ...valuesPeriod,
            ...item,
            funcionarios: funcionariosCurrentPeriod
          }
        })
      }
      const funcionariosTemp = {};
      store.funcionarios.forEach((func) => {
        funcionariosTemp[func.codFunc] = {
          ...func
        }
      })
      store.funcionarios = funcionariosTemp;

      dataReset[store.codLoja] = store
    });

    reset({ ...dataReset, temp: dataReset });

    setPeriodsSelects(storePeriods)
    setDataPage(dataReset)
  };

  const getDataPage = async () => {
    setIsLoadingData(true);
    try {
      let response;
      if (params?.id) {
        setRenderizarLojas(true);
        response = await metas.getEditData(params?.id);
      } else {
        response = await metas.getData();
      }

      formatterDataPage(response.data.metas, response.data.periodos || 1, !!params?.id);

      setFuncionariosNaoSelecionados(
        response.data.novosFuncionarios
      );
    } catch (error) {
      console.log(error);

      new ErrorToastHandler(error).showErrorToast();
    } finally {
      setIsLoadingData(false);
    }
  };

  const onSubmit = async (data) => {
    delete data.temp;
    const formDataObj = { ...data }
    const formData = Object.values(formDataObj);
    const formDataTargetFiltered = formData.filter((item) => !item.new || (item.new && item.metaVenda > 0));
    const sortedPeriods = periodsSelects.map(item => (
      { ...item, key: item.periodKey, dataInicio: item.start, dataFim: item.end }
    ))
      .sort((a, b) => new Date(a.startDate) - new Date(b.startDate));

    const formDataFormatted = formDataTargetFiltered.map((item) => {
      const diasRemovidos = item?.diasRemovidos?.map((item) => dayjs(item).format("YYYYMMDD")) || [];
      const funcionarios = Object.values(item.funcionarios);
      const periodosArray = Object.values(item.periodos);
      const periodos = periodosArray.map((itemPeriodo, index) => {
        const funcionariosPeriodo = Object.values(itemPeriodo.funcionarios);
        return {
          ...itemPeriodo,
          funcionarios: funcionariosPeriodo
        }
      })

      return {
        ...item,
        diasRemovidos,
        funcionarios,
        periodos
      }
    });
    const periodoInicio = dayjs(sortedPeriods[0].start).startOf('day').add(3, 'hours').format('YYYY-MM-DD HH:mm:ss');
    const periodoFim = dayjs(sortedPeriods[sortedPeriods.length - 1].end).endOf('day').format('YYYY-MM-DD HH:mm:ss');

    setIsSubmitting(true);

    if (params?.id) {

      try {
        const response = await metas.update(params.id,
          {
            metas: formDataFormatted,
            periodos: sortedPeriods,
            periodoInicio,
            periodoFim
          }
        );
        toast.success(response.data.msg);
        history.replace("/financeiro/metas");
      } catch (error) {
        new ErrorToastHandler(error).showErrorToast();
      } finally {
        setIsSubmitting(false);
      }
    } else {
      try {
        const response = await metas.create({
          metas: formDataFormatted,
          periodos: sortedPeriods,
          periodoInicio,
          periodoFim
        });
        toast.success(response.data.msg);
        history.replace("/financeiro/metas");
      } catch (error) {
        new ErrorToastHandler(error).showErrorToast();
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleImportMeta = async () => {
  }

  useEffect(() => {
    getDataPage();
  }, []);

  const fileInputRef = useRef(null);

  const handleFileInputClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log("Selected file:", file);
      // Add logic to handle the file upload
      const formData = new FormData();
      formData.append("spreadsheet", file);

      try {
        const response = await metas.importarTarget(formData, { periods: periodsSelects?.map(item => item.periodKey) || [] });
        console.log(response);
        if (response.status === 201) {
          toast.success("Planilha importada com sucesso!");
          const newData = response.data;
          const currentData = { ...getValues() };
          const newDataKeys = Object.keys(newData);
          newDataKeys.forEach((key) => {
            currentData[key] = newData[key];
            currentData.temp[key] = newData[key];
          });
          reset(
            currentData
          );
        }
      } catch (error) {
        console.log(error);

        new ErrorToastHandler(error).showErrorToast();
      }
    }
  };

  return (
    <FormContext.Provider
      value={{
        funcionariosNaoSelecionados,
        dataPage,
        periodsSelects,
        isLoadingData,
        currentCollapsedStore,
        setFuncionariosNaoSelecionados,
        setDataPage,
        formatterDataPage,
        setPeriodsSelects,
        setCurrentCollapsedStore,
      }}
    >
      <Title title="Cadastro de Metas" />
      <FormProvider {...methods}>
        {PermissionsPage("cadastMeta") && (
          <>
            <DateRages isEdit={!!params?.id || !!renderizarLojas} callback={() => setRenderizarLojas(true)} />
            {isLoadingData ? (
              <LoadingTable />
            ) : (
              !!params?.id || renderizarLojas ? (<Box onSubmit={(e) => e.preventDefault()} sx={{ mt: 2 }} component='form'>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  accept=".xlsx"
                />
                <Button
                  type="button"
                  variant="contained"
                  color="aprincipal"
                  disabled={isLoadingData}
                  style={{ display: 'flex', marginLeft: 'auto', marginBottom: '16px' }}
                  onClick={handleFileInputClick}
                >
                  Importar meta
                </Button>
                <Stack gap={2}>
                  {Object.values(dataPage).map((item) => (
                    <CardStore key={item.codLoja} item={item} />
                  ))}
                </Stack>
                <Stack py={2} direction={"row"} justifyContent={"flex-end"}>
                  <Button
                    type="button"
                    variant="contained"
                    color="aprincipal"
                    disabled={isSubmitting}
                    onClick={handleSubmit(onSubmit)}
                  >
                    Salvar e enviar {isSubmitting && <Spinner animation="border" style={{ marginLeft: '16px' }} />}
                  </Button>
                </Stack>
              </Box>) : <p className="p-5 text-center" style={{
                fontSize: '18px',
                fontStyle: 'italic',
              }}>Defina os períodos e clique em <b style={{ fontSize: '22px' }}>Salvar Períodos</b>, então as lojas aparecerão aqui</p>
            )}
          </>
        )}
      </FormProvider>
    </FormContext.Provider>
  );
};
