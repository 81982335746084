import {
  Box,
  Button,
  FormControl,
  FormGroup,
  Stack,
  TextField,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { NumericFormatCustom } from "../numericFormat";

export const FormTipoMeta = ({ name, callbackSave }) => {
  const { control, getValues, setValue } = useFormContext();

  const handleSave = () => {
    const tempValues = getValues(`temp.${name}`);
    const currentValues = getValues(`${name}`);
    setValue(`${name}`, {
      ...currentValues,
      ...tempValues
    });
    console.log({ name });

    console.log(getValues());

    callbackSave();
  }

  return (
    <Stack py={2} flex={1}>
      <FormGroup>
        <Box gap={2} display={"grid"} gridTemplateColumns={"1fr 1fr 1fr 1fr"}>
          <FormControl>
            <Controller
              id={`${name}.vendedor`}
              name={`temp.${name}.vendedor`}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  value={field?.value?.toString()}
                  sx={{
                    background: "white",
                  }}
                  size="small"
                  label="Vendedor"
                  id={`${name}.vendedor`}
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                  }}
                />
              )}
            />
          </FormControl>
          <FormControl>
            <Controller
              name={`temp.${name}.lider`}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={{
                    background: "white",
                  }}
                  label="Líder"
                  size="small"
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                  }}
                />
              )}
            />
          </FormControl>
          <FormControl>
            <Controller
              name={`temp.${name}.gerente`}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={{
                    background: "white",
                  }}
                  size="small"
                  label="Gerente"
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                  }}
                />
              )}
            />
          </FormControl>
          <FormControl>
            <Controller
              name={`temp.${name}.bonus_meta_loja`}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={{
                    background: "white",
                  }}
                  size="small"
                  label="Bônus meta loja"
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                  }}
                />
              )}
            />
          </FormControl>
        </Box>
      </FormGroup>
      <Stack
        justifyContent={"flex-end"}
        direction={"row"}
        alignItems={"flex-end"}
        mt={2}
      >
        <Button
          onClick={handleSave}
          color="aprincipal"
          variant="contained"
          size="small"
        >
          Salvar
        </Button>
      </Stack>
    </Stack>
  );
};
