import { formatarNumero } from "../../../util/number";

export const calculateWidth = (dados) => {
  const width = typeof window !== "undefined" ? window?.screen?.width : 0;
  const x = dados.labels;

  let length = x.length;
  console.log({ length, width });

  if (length <= 6) {
    return 1000;
  }

  if (length) {
    console.log("here");

    if (length * 110 < width) {
      console.log("here 2");
      return width;
    }
    console.log("here 3");
    return length * 90;
  }
  console.log("here 4");
  return width;
};

export const optionsChartBarMetas = ({
  isSm,
  dataDescontoAplicadoFromApi,
  dataChartLineFromApi,
}) => {
  console.log({ dataChartLineFromApi });

  return {
    maintainAspectRatio: false,
    responsive: false,
    legend: {
      position: "bottom",
      align: "start",
      reverse: true,
      labels: {
        filter: function (item, chart) {
          return (
            !item.text.includes("Desconto") &&
            !item.text.includes("Ouro") &&
            !item.text.includes("Diamante")
          );
        },
      },
    },
    layout: {
      padding: {
        top: 30,
        right: 50,
        left: dataChartLineFromApi?.length <= 6 ? 50 : 350,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          id: "A",
          ticks: {
            padding: 25,
            display: true,
          },
        },
        {
          id: "B",
          stacked: true,
          ticks: {
            padding: 25,
            display: false,
          },
          offset: true,
          offsetGridLines: false,
        },
      ],
    },
    tooltips: {
      displayColors: false,
      callbacks: {
        title: (tooltipItem, data) => {
          tooltipItem = tooltipItem[0];
          if (tooltipItem.datasetIndex == 0) {
            return "Valor Projetado Para " + tooltipItem.xLabel;
          } else if (tooltipItem.datasetIndex == 1) {
            return "Meta Para " + tooltipItem.xLabel;
          } else if (tooltipItem.datasetIndex == 2) {
            return "Meta Diamante para " + tooltipItem.xLabel;
          } else if (tooltipItem.datasetIndex == 3) {
            return "Meta Ouro para " + tooltipItem.xLabel;
          } else if (tooltipItem.datasetIndex == 4) {
            return "Valor Realizado Por " + tooltipItem.xLabel;
          } else if (tooltipItem.datasetIndex == 5) {
            let valorDesconto = formatarNumero(
              dataDescontoAplicadoFromApi[tooltipItem.index]
            );
            return "Total sem desconto " + valorDesconto;
          }
        },
        label: (tooltipItem, data) => {
          if (tooltipItem.datasetIndex != 5) {
            return (
              "Valor Total: " + formatarNumero(parseFloat(tooltipItem.value))
            );
          } else {
            let valorDesconto =
              dataDescontoAplicadoFromApi[tooltipItem.index] -
              dataChartLineFromApi[tooltipItem.index];
            return "Valor do Desconto: " + formatarNumero(valorDesconto);
          }
        },
        footer: (tooltipItem, data) => {
          tooltipItem = tooltipItem[0];
          if (tooltipItem.datasetIndex == 5) {
            let valorDesconto =
              dataDescontoAplicadoFromApi[tooltipItem.index] -
              dataChartLineFromApi[tooltipItem.index];
            let valorDescontoPorcentagem =
              (valorDesconto / dataDescontoAplicadoFromApi[tooltipItem.index]) *
              100;
            return (
              "Porcentagem " +
              formatarNumero(valorDescontoPorcentagem, false) +
              "%"
            );
          } else if (
            tooltipItem.datasetIndex === 3 ||
            tooltipItem.datasetIndex === 2
          ) {
            let totalRealizado = dataChartLineFromApi[tooltipItem.index];
            return (
              "Valor realizado " + formatarNumero(parseFloat(totalRealizado))
            );
          }
        },
      },
    },
    plugins: {
      datalabels: {
        labels: {
          title: {
            font: {
              weight: "bold",
            },
          },
        },
        formatter: function (value, context) {
          return parseFloat(value).toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        },
      },
    },
  };
};

export const optionsChartBarDesconto = (isSm) => ({
  maintainAspectRatio: !isSm,
  responsive: !isSm,
  legend: {
    position: "bottom",
    reverse: true,
    labels: {
      filter: function (item, chart) {
        return (
          !item.text.includes("DescontoPorcentagem") &&
          !item.text.includes("Ouro") &&
          !item.text.includes("Diamante")
        );
      },
    },
  },
  layout: {
    padding: {
      top: 30,
    },
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          display: false,
        },
      },
    ],
    xAxes: [
      {
        id: "A",
        ticks: {
          padding: 25,
          display: true,
        },
      },
    ],
  },
  tooltips: {
    displayColors: false,
  },
  plugins: {
    datalabels: {
      labels: {
        title: {
          font: {
            weight: "bold",
          },
        },
      },
      formatter: function (value, context) {
        if (!isNaN(parseFloat(value))) {
          return parseFloat(value).toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        } else {
          return parseFloat(0).toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        }
      },
    },
  },
});
