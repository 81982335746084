import { Button } from "react-bootstrap";
import styled from "styled-components";

export const ButtonFilter = styled(Button)`
  border-color: hsl(0, 0%, 80%);
  background-color: #828282de;
  border-color: hsl(0, 0%, 80%);
  width: 100%;
  &:hover {
    background-color: #828282ee;
    border-color: hsl(0, 0%, 80%);
  }
  &:disabled {
    background-color: #fff;
    border-color: #828282de;
    color: #b3b3b3;
    cursor: not-allowed;
  }
`;
