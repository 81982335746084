export const colourStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: state.isDisabled ? " whitesmoke" : "white",
  }),
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: " #eb0058",
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: "#FFF",
    fontWeight: "bold",
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: "white",
    ":hover": {
      backgroundColor: "#ff0d6c",
      color: "white",
    },
  }),
};

export const optionsChartBarVendas = {
  maintainAspectRatio: false,
  responsive: false,
  aspectRatio: 1,
  title: {
    display: true,
    padding: 0,
    text: "Valor Total de Vendas",
    fontColor: "#FC68A2",
    fontSize: 20,
    align: "left",
  },
  legend: {
    position: "bottom",
    align: "start",
  },
  layout: {
    padding: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
    xAxes: [
      {
        ticks: {
          padding: 25,
        },
      },
    ],
  },
  plugins: {
    datalabels: {
      labels: {
        title: {
          font: {
            weight: "bold",
          },
        },
      },
    },
  },
};

export function calculateWidth(dados) {
  console.log({ dados });

  const x = dados.labels;
  let length = x ? x.length : 0;
  if (length <= 13) {

    return 1000;
  }

  return length * 80;
}
