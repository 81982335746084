import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { IOSSwitch } from "../../pages/cadaastros/profile-indicate/components/IOSSwitch";
import { NumericFormatCustom } from "./FormSemana";
import { useState } from "react";

export const FormExpandableRowsPeriodo = ({
  handlePreviousTab,
  isFirstPeriod,
  handleSave,
  data,
  codLoja,
  isLastPeriod
}) => {
  const name = `${codLoja}.periodos.${data.periodKey}.funcionarios.${data.codFunc}`;
  const { control, getValues, setValue } = useFormContext();

  const [disabledInpus, setDisabledInput] = useState(
    !getValues(
      `temp.${name}.metaEspecial`
    )
  );

  const handleUpdateOrderQtd = () => {
    const values = getValues(`temp.${name}`);
    values.metaQtdPedidos = values.metaTM ? parseInt(values.metaVenda / values.metaTM) : 0;
    setValue(`temp.${name}.metaQtdPedidos`, values.metaQtdPedidos);
  }

  const handleUpdateTM = () => {
    const values = getValues(`temp.${name}`);

    values.metaTM = values.metaQtdPedidos ? parseInt(values.metaVenda / values.metaQtdPedidos) : 0;
    setValue(`temp.${name}.metaTM`, values.metaTM);
  }

  return (
    <Stack
      py={2}
      flex={1}
    >
      <FormGroup>
        <Box gap={2} display={"grid"} gridTemplateColumns={"1fr 1fr 1fr"}>
          <FormControl>
            <Controller
              id={`${name}.metaVenda`}
              name={`temp.${name}.metaVenda`}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  value={field?.value?.toString()}
                  sx={{
                    background: "white",
                  }}
                  size="small"
                  label={"Valor Vendas"}
                  id={`${name}.metaVenda`}
                  disabled={disabledInpus}
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                  }}
                  onBlur={handleUpdateOrderQtd}
                />
              )}
            />
          </FormControl>
          <FormControl>
            <Controller
              name={`temp.${name}.metaTM`}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={{
                    background: "white",
                  }}
                  label="Ticket Medio"
                  size="small"
                  id="formatted-numberformat-input"
                  disabled={disabledInpus}
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                  }}
                  onBlur={handleUpdateOrderQtd}
                />
              )}
            />
          </FormControl>
          <FormControl>
            <Controller
              name={`temp.${name}.metaQtdPedidos`}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={{
                    background: "white",
                  }}
                  disabled={disabledInpus}
                  type="number"
                  size="small"
                  label="Qtd Pedido"
                  onBlur={handleUpdateTM}
                />
              )}
            />
          </FormControl>
          <FormControl>
            <Controller
              name={`temp.${name}.metaItensPorVenda`}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  sx={{
                    background: "white",
                  }}
                  disabled={disabledInpus}
                  size="small"
                  label="Item por venda"
                />
              )}
            />
          </FormControl>
          <FormControl>
            <Controller
              name={`temp.${name}.metaClientesCadastrados`}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={{
                    background: "white",
                  }}
                  disabled={disabledInpus}
                  type="number"
                  size="small"
                  label="Clientes cadastrados"
                />
              )}
            />
          </FormControl>
        </Box>
      </FormGroup>
      <Stack
        justifyContent={"space-between"}
        direction={"row"}
        alignItems={"flex-end"}
        mt={2}
      >
        <Box ml={"12px"} mb={1} display={"flex"} alignItems={"center"}>
          <Controller
            name={`temp.${name}.metaEspecial`}
            control={control}
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={
                  <IOSSwitch
                    {...field}
                    checked={field.value}
                    onChange={(e) => {
                      setDisabledInput(!e.target.checked);
                      field.onChange(e);
                    }}
                  />
                }
              />
            )}
          />

          <Typography component={"label"}>Meta especial</Typography>
        </Box>
        <Stack gap={2} direction={"row"}>
          {
            !isFirstPeriod && <Button
              onClick={handlePreviousTab}
              color="aprincipal"
              size="small"
              variant="outlined"
            >
              Anterior
            </Button>
          }
          <Button
            onClick={() => handleSave()}
            color="aprincipal"
            variant="contained"
            size="small"
          >
            {isLastPeriod
              ? "Salvar Registro"
              : "Próximo Período"}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
