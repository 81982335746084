export function formatCurrency(value) {
  return new Intl.NumberFormat("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: "currency",
    currency: "BRL",
  }).format(parseFloat(value));
}

export function formatDecimal(
  value,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2
) {
  return new Intl.NumberFormat("pt-BR", {
    minimumFractionDigits,
    maximumFractionDigits,
    style: "decimal",
  }).format(parseFloat(value));
}

export function formatarNumero(numero, money = true, decimal = 2) {
  if (money) {
    return numero.toLocaleString("pt-BR", {
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal,
      style: "currency",
      currency: "BRL",
    });
  } else {
    return numero.toLocaleString("pt-BR", {
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal,
    });
  }
}
