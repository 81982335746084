import { Box } from "@mui/material";
import { FormExpandableRows } from "./FormExpandableRows";
import { useFormContext } from "react-hook-form";
import { updateEmployeeMonthlyTarget } from "../../util/formMetasFunctions";

export const FormRowFuncionario = ({ data, onSave, name }) => {
  const { getValues, setValue } = useFormContext();

  const handleSave = () => {
    const result = updateEmployeeMonthlyTarget(data.codLoja, data.codFunc, getValues, setValue);
    if (result) {
      onSave();
    }
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        py: 0.5,
      }}
    >
      <Box flex={1} px={0}>
        <FormExpandableRows
          name={name}
          handleSave={handleSave}
        />
      </Box>
    </Box>
  );
};
