import { createContext, useEffect, useState } from "react";
import { LoadingTable } from "../../../components/Loading/LoadingTable";
import Title from "../../../components/title";
import { PermissionsPage } from "../../../util/permissionsPage";
import { Box, Button } from "@mui/material";
import ErrorToastHandler from "../../../util/ErrorToastHandler";
import dayjs from "dayjs";
import "react-datepicker/dist/react-datepicker.css";
import { Container } from "react-bootstrap";
import premiacoes from "../../../services/premiacoes";
import metas from "../../../services/metas";
import BootstrapTable from "react-bootstrap-table-next";
import { colunasResultados, rowClasses } from "./columns";
import filterFactory from "react-bootstrap-table2-filter";
import { SelectFilterMonth } from "../../../components/FilterLojaMetas/SelectMes";

export const FormContext = createContext();
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");

// Adiciona os plugins necessários ao dayjs
dayjs.extend(utc);
dayjs.extend(timezone);

export const ResultadosMeta = () => {
  const [paramsConfig, setParamsConfig] = useState(null);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [periodosDisponiveis, setPeriodosDisponiveis] = useState([]);
  const [resultadosMeta, setResultadosMeta] = useState([]);

  const formatData = (data) => {

    const metas = data.metas || [];
    console.log({ metas });
    setResultadosMeta(metas);

  }

  function selecionarPeriodo(value) {
    setParamsConfig((old) => ({
      ...old,
      periodo: value,
    }));
  }

  const getAvailablePeriods = async () => {
    try {
      const response = await metas.getAvailableTargets();
      const data = response.data;
      const lastMonth = dayjs().subtract(1, 'month').format('MMM/YYYY');
      const firstMetaLastMonth = data.find(item => item.periodo?.toLowerCase() === lastMonth);
      console.log({ firstMetaLastMonth });

      setPeriodosDisponiveis(data);
      setParamsConfig({ periodo: firstMetaLastMonth?.id });
    } catch (error) {
      new ErrorToastHandler(error).showErrorToast();
    }
  };

  const getDataPage = async () => {
    setIsLoadingData(true);
    try {

      if (!paramsConfig.periodo) {
        setIsLoadingData(false);
        return;
      }
      const response = await premiacoes.getResults(paramsConfig.periodo);
      formatData(response.data);
    } catch (error) {
      console.log(error);
      setResultadosMeta([]);
      new ErrorToastHandler(error).showErrorToast();
    } finally {
      setIsLoadingData(false);
    }
  };

  useEffect(() => {
    console.log({ paramsConfig });

    if (
      !!paramsConfig?.periodo
    ) {
      getDataPage();
    }
  }, [paramsConfig]);

  useEffect(() => {
    getAvailablePeriods();
  }, []);

  return (
    <div>
      <Title title="Resultado Mensal" />
      {
        PermissionsPage("resultadosMetas") && (
          <>
            {isLoadingData ? (
              <LoadingTable />
            ) : (
              (<Box sx={{ mt: 2 }}>
                <Box mt={1} width={"100%"}>
                  <SelectFilterMonth
                    periodoReferencia={paramsConfig?.periodo}
                    periodosDisponiveis={periodosDisponiveis}
                    selecionarPeriodo={selecionarPeriodo}
                  />
                </Box>
                <Box mt={1} mb={4} width={"100%"} display="flex" justifyContent="flex-end" onClick={() => window.print()}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="aprincipal"
                    className="nao-imprimir"
                  >Imprimir/Salvar</Button>
                </Box>
                <BootstrapTable
                  keyField="loja"
                  data={resultadosMeta}
                  columns={colunasResultados}
                  // bootstrap4={true}
                  rowClasses={rowClasses}
                  noDataIndication={<Container>Nenhuma Loja Disponível</Container>}
                  filter={filterFactory()}
                />
              </Box>)
            )}
          </>
        )
      }
    </div>
  );
};
