import { Box, Button, Card, Stack, TextField, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { FormContext } from "../../pages/financeiro/metas/cadastro-metas";
import { addDays } from "date-fns";
import DatePicker from "react-datepicker";
import { SimpleDialogDelete } from "../SimpleDialogDelete/SimpleDialogDelete";
import { addPeriodToTarget, removePeriodFromTarget } from "../../util/formMetasFunctions";
import { useFormContext } from "react-hook-form";
import { FaArrowRight } from "react-icons/fa";

export const DateRages = ({ isEdit, callback }) => {
  const { setPeriodsSelects, periodsSelects, isLoadingData } =
    useContext(FormContext);

  const { getValues, setValue } = useFormContext()

  const [periodToDelete, setPeriodToDelete] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setPeriodToDelete(null);
  };

  const handleOpenDialog = (index) => {
    setPeriodToDelete(index);
    setOpenDialog(true);
  };

  const handleAddPeriod = () => {
    const newPeriodKey = Math.random().toString(36).substring(2, 12);
    const newPeriod = {
      periodKey: newPeriodKey,
      start: periodsSelects.length === 0
        ? new Date(new Date().getFullYear(), new Date().getMonth(), 0).toISOString().split('T')[0]
        : new Date(new Date(periodsSelects[periodsSelects.length - 1].end).getFullYear(), new Date(periodsSelects[periodsSelects.length - 1].end).getMonth() + 1, 0).toISOString().split('T')[0],
      end: periodsSelects.length === 0
        ? new Date(new Date().getFullYear(), new Date().getMonth(), 0).toISOString().split('T')[0]
        : new Date(new Date(periodsSelects[periodsSelects.length - 1].end).getFullYear(), new Date(periodsSelects[periodsSelects.length - 1].end).getMonth() + 1, 0).toISOString().split('T')[0],
    }

    setPeriodsSelects((oldValue) => ([
      ...oldValue,
      newPeriod
    ]
    ));
    addPeriodToTarget(newPeriod, getValues, setValue);
  };

  const handleRemovePeriod = () => {

    const copiaDoEstado = [...periodsSelects]; // Crie uma cópia do estado

    if (periodToDelete > 0) {
      // Merge with the previous period
      copiaDoEstado[periodToDelete - 1].end = copiaDoEstado[periodToDelete].end;
    } else {
      // Merge with the next period
      copiaDoEstado[periodToDelete + 1].start = copiaDoEstado[periodToDelete].start;
    }

    copiaDoEstado.splice(periodToDelete, 1); // Remova o item do array

    removePeriodFromTarget(periodsSelects[periodToDelete]?.periodKey, getValues, setValue);
    setPeriodsSelects(copiaDoEstado);
    handleCloseDialog();
  };

  const handleDateChange = (date, index, type) => {
    console.log({ date });
    const copiaDoEstado = [...periodsSelects];
    copiaDoEstado[index][type] = date.toISOString().split('T')[0];
    console.log({ copiaDoEstado });

    setPeriodsSelects(copiaDoEstado);
  }

  return (
    <Card sx={{ padding: 2 }}>
      <Stack direction="row" mb={2} gap={2} justifyContent="center">
        <Box
          component="section"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Typography component="label" fontWeight="500" mb={2}>
            Selecione os períodos
          </Typography>
          {periodsSelects ? periodsSelects.map((item, index) => {
            const startDate = item.start ? new Date(`${item.start} 12:00:00`) : '';
            const endDate = item.end ? new Date(`${item.end} 12:00:00`) : '';

            let minDateToStartDate = undefined;

            if (index > 0 && periodsSelects[index - 1].end) {
              minDateToStartDate = addDays(new Date(`${periodsSelects[index - 1].end} 12:00:00`), 1);
            }

            return <Box
              variant="outlined"
              display="flex"
              gap={2}
              mb={2}
              key={item.start + index}
            >
              <DatePicker
                selected={startDate}
                onChange={(date) => handleDateChange(date, index, "start")}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                minDate={minDateToStartDate}
                disabled={isEdit}
                customInput={
                  <TextField
                    sx={{ background: "white" }}
                    size="small"
                    label="Início"
                    id="formatted-numberformat-input"
                  />
                }
              />
              <DatePicker
                selected={endDate}
                onChange={(date) => handleDateChange(date, index, "end")}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                disabled={isEdit}
                customInput={
                  <TextField
                    sx={{ background: "white" }}
                    size="small"
                    label="Fim"
                    id="formatted-numberformat-input"
                  />
                }
              />
              {!isEdit && <Button
                variant="outlined"
                color="error"
                onClick={() => handleOpenDialog(index)}
                disabled={isLoadingData}
              >
                Remover
              </Button>}
            </Box>
          }) : null}
          {!isEdit && <Stack direction="row" gap={1}>
            <Button
              variant="outlined"
              onClick={handleAddPeriod}
              disabled={isLoadingData}
              color="success"
            >
              Adicionar Período
            </Button>
          </Stack>}
        </Box>
      </Stack>
      {!isEdit && <Box display={"flex"} justifyContent={"flex-end"}>
        <FaArrowRight size={34} style={{ marginRight: 2 }} />
        <Button
          type="button"
          variant="contained"
          color="aprincipal"
          onClick={callback}
          disabled={isLoadingData}
        >
          Salvar períodos
        </Button>
      </Box>}

      <SimpleDialogDelete
        open={openDialog}
        onClose={handleCloseDialog}
        body={`Tem certeza que deseja remover o período da meta?`}
        onDelete={() => handleRemovePeriod()}
      />
    </Card>
  );
};
