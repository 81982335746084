import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { PermissionsPage } from "../../util/permissionsPage";
import { BuntonLink } from "./Button/BuntonLink";
import { useUserData } from "../../hooks/useUserData";
import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import {
  FcStatistics,
  FcMoneyTransfer,
  FcSalesPerformance,
} from "react-icons/fc";
import { LiaBoxesSolid } from "react-icons/lia";
import { FaCubes } from "react-icons/fa";

import { grey, pink } from "@mui/material/colors";
import { vendasService } from "../../services/vendas";
const initialValues = () => {
  return { ticketMedio: "", valorTotal: "", totalPedidos: "" };
};
export const CardHomeVendasDias = () => {
  const { userData } = useUserData();
  const acessBase = userData?.baseAcesso;

  const [values, setValues] = useState(initialValues);

  useEffect(() => {
    if (acessBase) {
      receberDados();
    }
  }, [userData]);

  const receberDados = async () => {
    const response = await vendasService.homeSummary();
    if (!response?.data) {
      return
    }
    const { ticketMedio, valorTotal, totalPedidos, maiorValor, maiorQTD, base } =
      response.data;
    setValues({
      ...values,
      ticketMedio,
      valorTotal,
      totalPedidos,
      maiorValor,
      maiorQTD,
      base
    });
  };

  return (
    <>
      {PermissionsPage("vendDia") ? (
        <>
          {values.totalPedidos === "" ? (
            <Box
              flex={1}
              component={Paper}
              elevation={3}
              variant="outlined"
              p={2}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              width={["100%", "50%"]}
              mb={4}
            >
              <Spinner animation="border" />
            </Box>
          ) : values.totalPedidos === 0 ? (
            <Box
              flex={1}
              component={Paper}
              elevation={3}
              variant="outlined"
              display={"flex"}
              mb={4}
              alignItems={"center"}
              justifyContent={"center"}
              p={2}
              width={"50%"}
            >
              <h6>Hoje ainda não foi realizado nenhuma venda</h6>
            </Box>
          ) : (
            <>
              <Box
                flex={1}
                component={Paper}
                variant="outlined"
                elevation={3}
                p={2}
                mb={4}
              >
                <Typography mb={1}>
                  Resumo das Vendas de Hoje da Base {values.base || ''}
                </Typography>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  direction={["column", "row"]}
                >
                  <Grid item xs={[6]}>
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      p={1}
                      alignItems={"flex-end"}
                      justifyContent={"space-between"}
                      component={Paper}
                      elevation={1}
                      variant="outlined"
                    >
                      <Typography
                        variant="h6"
                        component={"span"}
                        fontWeight={"normal"}
                      >
                        Quantidade
                      </Typography>
                      <Box
                        display={"flex"}
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                        width={"100%"}
                      >
                        <LiaBoxesSolid fontSize="2rem" color={grey[700]} />
                        <Typography component={"h5"} variant="h6">
                          {values.totalPedidos}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      p={1}
                      alignItems={"flex-end"}
                      justifyContent={"space-between"}
                      variant="outlined"
                      component={Paper}
                      elevation={1}
                    >
                      <Typography
                        variant="h6"
                        component={"span"}
                        fontWeight={"normal"}
                      >
                        Media
                      </Typography>
                      <Box
                        display={"flex"}
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                        width={"100%"}
                      >
                        <FcStatistics fontSize="2rem" />
                        <Typography component={"h5"} variant="h6">
                          {values.ticketMedio}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      variant="outlined"

                      p={1}
                      alignItems={"flex-end"}
                      justifyContent={"space-between"}
                      component={Paper}
                      elevation={1}
                    >
                      <Typography
                        variant="h6"
                        component={"span"}
                        fontWeight={"normal"}
                      >
                        Total Vendas
                      </Typography>
                      <Box
                        display={"flex"}
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                        width={"100%"}
                      >
                        <FcMoneyTransfer fontSize="2rem" />
                        <Typography component={"h5"} variant="h6">
                          {values.valorTotal}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Box
                  display={"flex"}
                  width={"100%"}
                  justifyContent={"flex-end"}
                >
                  <BuntonLink path={`/vendas/vendas-global`}>
                    IR PARA PAGINA
                  </BuntonLink>
                </Box>

                <Divider
                  sx={{
                    my: 2,
                  }}
                />
                <Box>
                  <Typography mb={1}>
                    Loja com o maior valor total de vendas
                  </Typography>
                  <Box>
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      p={1}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      component={Paper}
                      elevation={1}
                      variant="outlined"

                    >
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        width={"100%"}
                      >
                        <Box
                          flexWrap={"wrap"}
                          display={"flex"}
                          flexDirection={"row"}
                          gap={1}
                          alignItems={"center"}
                          mb={0.5}
                        >
                          <Typography
                            fontWeight={"bold"}
                            color={"white"}
                            bgcolor={pink[400]}
                            px={1}
                            py={0.5}
                            width={"fit-content"}
                          >
                            {values?.maiorValor?.siglaLoja}
                          </Typography>
                        </Box>
                        <Typography
                          variant="h6"
                          component={"span"}
                          fontWeight={"normal"}
                        >
                          Total Vendas
                        </Typography>
                      </Box>
                      <Box
                        display={"flex"}
                        flexDirection={["column", "row"]}
                        alignItems={["stretch", "center"]}
                        justifyContent={"space-between"}
                        width={"100%"}
                      >
                        {values?.maiorValor?.repesentatividadeValor > 0 && (
                          <Typography gap={0.5} display={"flex"}>
                            <Typography
                              component={"span"}
                              display={["none", "block"]}
                            >
                              Representa
                            </Typography>
                            <Typography
                              component={"span"}
                              display={["block", "none"]}
                            >
                              Repre.
                            </Typography>
                            {values?.maiorValor?.repesentatividadeValor} % do
                            valor das vendas
                          </Typography>
                        )}
                        <Box
                          display={"flex"}
                          alignItems={["flex-end", "center"]}
                          width={["100%", "auto"]}
                          justifyContent={"flex-end"}
                          gap={1}
                        >
                          <FcSalesPerformance fontSize="2rem" />
                          <Typography component={"h5"} variant="h6">
                            {values?.maiorValor?.vlrTotalFormatado}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Divider
                  sx={{
                    my: 2,
                  }}
                />
                <Box>
                  <Typography mb={1}>
                    Loja com o maior número de vendas
                  </Typography>
                  <Box>
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      p={1}
                      alignItems={["stretch", "center"]}
                      justifyContent={"space-between"}
                      component={Paper}
                      elevation={1}
                      variant="outlined"

                    >
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        width={"100%"}
                      >
                        <Box
                          flexWrap={"wrap"}
                          display={"flex"}
                          flexDirection={"row"}
                          gap={1}
                          alignItems={"center"}
                          mb={0.5}
                        >
                          <Typography
                            fontWeight={"bold"}
                            color={"white"}
                            bgcolor={pink[400]}
                            px={1}
                            py={0.5}
                            width={"fit-content"}
                          >
                            {values?.maiorQTD?.siglaLoja}
                          </Typography>
                        </Box>
                        <Typography
                          variant="h6"
                          component={"span"}
                          fontWeight={"normal"}
                        >
                          Quantide de Vendas
                        </Typography>
                      </Box>
                      <Box
                        display={"flex"}
                        flexDirection={["column", "row"]}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        width={"100%"}
                      >
                        {values?.maiorQTD?.repesentatividadeQTD > 0 && (
                          <Typography
                            gap={0.5}
                            display={"flex"}
                            width={["100%", "auto"]}
                          >
                            <Typography
                              component={"span"}
                              display={["none", "block"]}
                            >
                              Representa
                            </Typography>
                            <Typography
                              component={"span"}
                              display={["block", "none"]}
                            >
                              Repre.
                            </Typography>
                            {values?.maiorQTD?.repesentatividadeQTD} % da
                            <Typography
                              component={"span"}
                              display={["none", "block"]}
                            >
                              quantidade
                            </Typography>
                            <Typography
                              component={"span"}
                              display={["block", "none"]}
                            >
                              qtd.
                            </Typography>
                            de vendas
                          </Typography>
                        )}
                        <Box
                          display={"flex"}
                          alignItems={["flex-end", "center"]}
                          width={["100%", "auto"]}
                          justifyContent={"flex-end"}
                          gap={1}
                        >
                          <FaCubes fontSize="2rem" color={grey[700]} />
                          <Typography component={"h5"} variant="h6">
                            {values?.maiorQTD?.totalPedidos}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </>
      ) : (
        <h6>Sem permissão</h6>
      )}
    </>
  );
};
