import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useContext, useState } from "react";

import { FormContext } from "../../pages/financeiro/metas/cadastro-metas";
import dayjs from "dayjs";
import { CustomTabPanel } from "../componentsAnaliseClientes/CustomTabPanel";
import { a11yProps } from "../../util/a11yProps";
import { FormSenanal } from "./FormSemana";
import { updateStoreWeeklyTarget } from "../../util/formMetasFunctions";
import { useFormContext } from "react-hook-form";

export const FormEditarMetasSemanal = ({ data, open, onClickSave }) => {
  const { getValues, setValue } = useFormContext();

  const { periodsSelects } = useContext(FormContext);

  const [valueTab, setValueTab] = useState(0);

  const handleSave = (key) => {
    console.log({ key });

    updateStoreWeeklyTarget(data.codLoja, key, getValues, setValue);
    if (valueTab + 1 === Object.values(periodsSelects).length) {
      setValueTab(0);
      onClickSave();
    } else {
      setValueTab((old) => old + 1);
    }
  };

  const handlePreviousTab = () => {
    if (valueTab === 0) {
      return;
    }
    setValueTab((old) => old - 1);
  }

  return (
    <>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          flexGrow: 1,
          bgcolor: "background.paper",

          display: open ? "flex" : "none",
        }}
      >
        <Tabs
          value={valueTab}
          // onChange={handleChange}
          aria-label="basic tabs example"
          orientation="vertical"
          variant="scrollable"
        >
          {periodsSelects.map((item, index) => (
            <Tab
              sx={{
                borderRight: 1,
                borderColor: "divider",
              }}
              key={index}
              label={`${dayjs(item.start).format("DD/MM")} - ${dayjs(
                item.end
              ).format("DD/MM")}`}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>

        <Box flex={1} p={2} pt={0}>
          {periodsSelects.map((item, index) => {
            return (
              <CustomTabPanel
                key={item.start + item.end + index}
                value={valueTab}
                index={index}
              >
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  fontWeight={"500"}
                  component={"span"}
                  gutterBottom
                >
                  Período {valueTab + 1} -
                  {` ${dayjs(item.start).format(
                    "DD/MM/YYYY"
                  )} - ${dayjs(item.end).format(
                    "DD/MM/YYYY"
                  )}`}
                </Typography>

                <FormSenanal
                  isLastPeriod={valueTab + 1 === Object.values(periodsSelects).length}
                  data={data}
                  handleSave={() => handleSave(item.periodKey)}
                  isFirstPeriod={valueTab === 0}
                  handlePreviousTab={handlePreviousTab}
                  periodKey={item.periodKey}
                />
              </CustomTabPanel>
            );
          })}
        </Box>
      </Box>
    </>
  );
};
