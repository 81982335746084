import { createContext, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { api, registerInterceptTokenManager } from "../services/api.v2";
import {
  storageAccessTokenGet,
  storageAccessTokenSave,
} from "../storage/storageAuthToken";
import { storageStoresSave } from "../storage/storageStores";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const history = useHistory();

  const [isLoadingUserData, setIsLoadingUserData] = useState(true);
  const [accessToken, setAccessToken] = useState({});

  const singOut = (returnToCurrentPage = false) => {
    const currentLocation = window.location.href
    storageAccessTokenSave(null);

    if (returnToCurrentPage) {
      return window.location.href = `https://aprincipalbb.com.br/portal/login?redirect=${currentLocation}`;
    }
    return window.location.href = `https://aprincipalbb.com.br/portal/login`;
  };

  useEffect(() => {
    const removeInterceptor = registerInterceptTokenManager(singOut);

    return () => {
      removeInterceptor();
    };
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const accessToken = params.get("access_token");

    const setAccessTokenLocal = async () => {
      try {
        if (!!accessToken) {
          storageAccessTokenSave(accessToken);
          setAccessToken(accessToken)
          params.delete("access_token");

          const newSearch = params.toString();

          history.replace({
            pathname: window.location.pathname,
            search: newSearch
          });
          setIsLoadingUserData(false);
        } else {
          const accessTokenStorage = storageAccessTokenGet();
          setAccessToken(accessTokenStorage)

        }
      } catch (error) { }
    };

    const setAvailableStores = async () => {
      try {
        const response = await api.get("/base/available-stores");
        if (response.status === 200) {
          storageStoresSave(response.data);
        }
      } catch (error) {
        console.log({ error });
      }
    };

    setAccessTokenLocal();
    setAvailableStores();
  }, []);

  return (
    <AuthContext.Provider value={{ isLoadingUserData, singOut, accessToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
