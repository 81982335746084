import { Box, Button, Card, CardContent, Collapse, Stack, Typography } from "@mui/material";
import { ButtonExpand } from "../ExpandMore";
import { TableSemanal } from "./TableSemanal";
import { FormEditarMetasSemanal } from "./FormEditarMetasSemanal";
import { TableFuncionariosPeriodos } from "./TableFuncionariosPeriodos";
import { useState } from "react";
import { useFormContext } from "react-hook-form";

export const CollapseMetaSemanal = ({ shouldDisable, item }) => {
    const { getValues, setValue } = useFormContext()

    const [expanded, setExpanded] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);


    const handleEditSemanalClick = () => setOpenEdit((old) => !old);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleCloseEdit = () => {
        setOpenEdit((old) => !old);
        setValue(`temp.${item.codLoja}`, {
            ...getValues(`${item.codLoja}`)
        })
    }

    return <CardContent
        sx={{
            mt: 1,
            pt: 0
        }}
    >
        <Card sx={{ padding: 1 }}>
            <Stack
                mt={2}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
                sx={{
                    cursor: "pointer"
                }}
                onClick={handleExpandClick}
            >
                <Box>
                    <ButtonExpand
                        handleExpandClick={() => { }}
                        expanded={expanded}
                    />
                    <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        fontWeight={"500"}
                        component={"span"}
                        mb={0}
                        gutterBottom
                    >
                        Meta semanal
                    </Typography>

                </Box>
                {expanded && <Button
                    onClick={(e) => { e.stopPropagation(); handleCloseEdit() }}
                    size="small"
                    variant="outlined"
                    disabled={shouldDisable}
                >
                    {openEdit ? "Fechar edição" : "Editar metas semanal"}
                </Button>}
            </Stack>
            <Collapse in={expanded}>

                <TableSemanal data={item} open={openEdit} />
                <FormEditarMetasSemanal
                    data={item}
                    open={openEdit}
                    onClickSave={handleEditSemanalClick}
                />

                <Stack
                    mt={2}
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"flex-end"}
                >
                    <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        fontWeight={"500"}
                        component={"span"}
                        mb={0}
                        gutterBottom
                    >
                        Funcionários
                    </Typography>
                </Stack>
                <TableFuncionariosPeriodos
                    data={item}
                />
            </Collapse>
        </Card>
    </CardContent>
}