import React, { useState } from "react";

import img from "../../assets/coracao.png";

import { useHistory } from "react-router";

import { useUserData } from "../../hooks/useUserData";

import {
  AppBar as MuiAppBar,
  Drawer as MuiDrawer,
  IconButton,
  Toolbar,
  useTheme,
  styled,
  Box,
  Container,
  useMediaQuery,
  Avatar,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import { stringToColor } from "../../pages/franchise/utils/formatted";
import { MenuProfile } from "./components/MenuProfile";
import { ListMenu } from "./components/ListMenu";
const drawerWidth = 300;
function stringAvatar(name) {
  if (!name) return <></>;
  const nameParts = name.split(" ");

  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${nameParts[0][0]}${
      nameParts.length > 1 ? nameParts[1][0] : ""
    }`,
  };
}
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  overflowY: "visible",
  top: "64px",
  marginRight: 12,
  marginLeft: 12,
  borderRadius: 6,
  height: "auto",
});

const closedMixin = (theme, open) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  display: open ? "none" : "block",
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
  top: "64px",
  marginRight: 12,
  marginLeft: 12,
  borderRadius: 6,
  height: "auto",
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  margin: "6px 12px",
  borderRadius: 6,
  "& .MuiToolbar-root": {
    minHeight: "50px !important",
  },
  width: "auto",
  left: 0,
  boxShadow:
    "0px 1px 3px 0px rgba(0,0,0,0.12), 0px 1px 2px 0px rgba(0,0,0,0.24)",
  backgroundColor: "#ff7aae",
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",

  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme, open),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),

  "& .MuiPaper-elevation": {
    boxShadow:
      "0px 1px 3px 0px rgba(0,0,0,0.12), 0px 1px 2px 0px rgba(0,0,0,0.24)",
    position: "absolute",
  },
}));

const NavBarAll = ({ children }) => {
  const history = useHistory();
  const theme = useTheme();

  const isLg = useMediaQuery(theme.breakpoints.up("lg"));
  const isSm = useMediaQuery(theme.breakpoints.up("sm"));

  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenuProfile = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { userData } = useUserData();

  const linkToHome = `/`;
  const handleImageToHome = (e) => {
    e.preventDefault();
    history.push(linkToHome);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <MenuProfile
        onClose={handleClose}
        open={openMenuProfile}
        anchorEl={anchorEl}
      />
      <AppBar position="fixed" open={open} className="nao-imprimir">
        <Toolbar>
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            width={"100%"}
          >
            <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => setOpen(!open)}
                edge="start"
                sx={{
                  marginRight: 5,
                }}
              >
                <MenuIcon />
              </IconButton>
              <Box component={"a"} href="/" onClick={handleImageToHome}>
                <img width={"53px"} alt="logo" src={img} />
              </Box>
            </Box>
            <Box>
              <IconButton onClick={handleClick} size="small">
                <Avatar {...stringAvatar(userData?.user)} />
              </IconButton>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      {userData?.menu?.length > 0 && (
        <>
          {!isSm || !isLg ? (
            <MuiDrawer
              sx={{
                display: "flex",
                overflowY: "auto",
                "& .MuiPaper-root": {
                  top: "67px",
                },
                "& .MuiDrawer-paper": {
                  width: drawerWidth,
                  ml: 2,
                  borderRadius: 1.5,
                  height: "fit-content",
                  position: "absolute",
                  whiteSpace: "nowrap",
                  overflowY: "auto",
                },
              }}
              anchor={"left"}
              open={open}
              className="nao-imprimir"
            >
              <ListMenu open={open} setOpen={setOpen} userData={userData} />
            </MuiDrawer>
          ) : (
            <Drawer variant={"permanent"} open={open} className="nao-imprimir">
              <ListMenu open={open} setOpen={setOpen} userData={userData} />
            </Drawer>
          )}
        </>
      )}

      <Container
        component="main"
        sx={{
          flexGrow: 1,
          paddingX: "0px !important",
          mt: "64px",
          maxWidth: "1100px",
        }}
      >
        <Box
          px={2}
          sx={{
            pb: 2,
            backgroundColor: "#fff",
            borderRadius: "4px",
          }}
        >
          {children}
        </Box>
      </Container>
    </Box>
  );
};

export default NavBarAll;
