import React, { useState } from "react";

import { Container, Row } from "react-bootstrap";
import "chartjs-plugin-datalabels";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import Title from "../../../components/title";

import { PermissionsPage } from "../../../util/permissionsPage";
import { Accordion, AccordionDetails, AccordionSummary, Box, Paper, Stack, Tab, Tabs, Typography } from "@mui/material";
import { LoadingTable } from "../../../components/Loading/LoadingTable";
import Select from "react-select";
import { useRadarVendas } from "./useRadarVendas";
import {  ButtonToday } from "../../../components/DatePikerFilter/styles";
import { a11yProps } from "../../../util/a11yProps";
import { ExpandMore } from "@mui/icons-material";
import { TabSoldDataCards } from "./components/TabSoldDataCards";
import { TabSoldDataChart } from "./components/TabSoldDataChart";
import { TabNumberOfSalesCards } from "./components/TabNumberOfSalesDataCards";
import { TabNumberOfSalesChart } from "./components/TabNumberOfSalesDataChart";
import { TabCashbackCards } from "./components/TabCashbackDataCards";
import { TabCashbackChart } from "./components/TabCashbackDataChart";
import { formatDate } from "../../../util/strings";
import { DatePikerFilter } from "../../../components/DatePikerFilterV2";

const colourStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: state.isDisabled ? " whitesmoke" : "white",
  }),
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: " #eb0058",
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: "#FFF",
    fontWeight: "bold",
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: "white",
    ":hover": {
      backgroundColor: "#ff0d6c",
      color: "white",
    },
  }),
};

function RadarDeVendas(props) {

  const {
    isLoading,
    basesDisponiveis,
    lojasDisponiveis,
    defaultValueBase,
    lojasSelecionadas,
    totalSoldData,
    periodoInputInicio,
    periodoInputFim,
    totalNumberOfSalesData,
    cashbackData,
    periods,
    filtrarBases,
    handleFiltroLoja,
    handleFilter,
    handleChangeDate
  } = useRadarVendas()

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="container-app">
        {PermissionsPage("radar-de-vendas") && (
          <Container>
            <div className="container-conteudo">
              <Box ml={2}>
                <Title title="Radar de vendas/cliente" />
              </Box>

              <Stack gap={2} direction="row" mx={2}>
                <Box px={1} pb={1} pt={2}>
                  {/* <FiltroPeriodo
                    periodoInputInicioAux={periodoInputInicio}
                    periodoInputFimAux={periodoInputFim}
                    setPeriodoInputInicioAux={setPeriodoInputInicio}
                    setPeriodoInputFimAux={setPeriodoInputFim}
                  /> */}
                  <DatePikerFilter
                    paramsConfig={{
                      first_period: periodoInputInicio,
                      final_period: periodoInputFim,
                    }}
                    onChange={handleChangeDate}
                    isLoading={isLoading}
                    showButtonFilter={false}
                  />
                </Box>
                <Box width="100%">
                  <Box pb={1}>
                    <Typography
                      textAlign={"initial"}
                      fontWeight={"bold"}
                      component={"span"}>
                      Base
                    </Typography>
                    <Select
                      options={basesDisponiveis.map((item) => ({
                        label: item.base,
                        value: item.codBase,
                      }))}
                      onChange={(value) => filtrarBases(value.value)}
                      placeholder="Selecione uma base"
                      styles={colourStyles}
                      isDisabled={isLoading}
                      value={{ label: defaultValueBase?.base, value: defaultValueBase?.codBase }}
                    />
                  </Box>
                  <Box>
                    <Typography
                      textAlign={"initial"}
                      fontWeight={"bold"}
                      component={"span"}>
                      Lojas
                    </Typography>
                    <Select
                      options={lojasDisponiveis.map((item) => ({
                        label: item,
                        value: item,
                      }))}
                      isMulti
                      onChange={(values) => handleFiltroLoja(values.map(item => item.value))}
                      placeholder="Selecione uma loja"
                      styles={colourStyles}
                      isDisabled={isLoading}
                      value={lojasSelecionadas}
                    />
                    <Typography variant="caption" component={"span"}>
                      *Atenção: Quando nenhuma loja estiver selecionada, todas as
                      lojas serão retornadas.*
                    </Typography>
                  </Box>

                  <Box style={{ width: '100%', marginTop: '1rem' }}>
                    <ButtonToday
                      style={{ width: '100%' }}
                      type="button"
                      onClick={() => handleFilter()}
                      disabled={isLoading}
                    >
                      Filtrar
                    </ButtonToday>
                  </Box>
                </Box>
              </Stack>
              {isLoading ? (
                <Box
                  width={"100%"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  height={"90vh"}
                >
                  <LoadingTable isLoading={isLoading} />
                </Box>
              ) : (
                <Container className="mt-2">
                  <small>
                    {
                      periods ? <div>
                        <div>Ano Atual: de <strong>{formatDate(periods.startPeriod)}</strong> a <strong>{formatDate(periods.finalPeriod)}</strong></div>
                        <div>Ano Anterior: de <strong>{formatDate(periods.periodStartLastYear)}</strong> a <strong>{formatDate(periods.periodEndLastYear)}</strong></div>
                        <div>Período Anterior: de <strong>{formatDate(periods.periodStartPastPeriod)}</strong> a <strong>{formatDate(periods.periodEndPastPeriod)}</strong></div>
                      </div> : null
                    }
                  </small>
                  <Accordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      VALOR VENDIDO
                    </AccordionSummary>
                    <AccordionDetails>
                      <Row className="wrapper">
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                          <Tabs
                            value={value}
                            onChange={handleChange}
                            sx={{
                              "& .MuiTabs-flexContainer": {
                                width: "100%",
                                justifyContent: "center",
                              },
                            }}
                            aria-label="basic tabs example"
                          >
                            <Tab label="Cards" {...a11yProps(0)} />
                            <Tab label="Gráfico" {...a11yProps(1)} />
                          </Tabs>
                        </Box>

                        <TabSoldDataCards data={totalSoldData} value={value} />
                        <TabSoldDataChart data={totalSoldData} value={value} />
                      </Row>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      NÚMERO DE COMPRAS
                    </AccordionSummary>
                    <AccordionDetails>
                      <Row className="wrapper">
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                          <Tabs
                            value={value}
                            onChange={handleChange}
                            sx={{
                              "& .MuiTabs-flexContainer": {
                                width: "100%",
                                justifyContent: "center",
                              },
                            }}
                            aria-label="basic tabs example"
                          >
                            <Tab label="Cards" {...a11yProps(0)} />
                            <Tab label="Gráfico" {...a11yProps(1)} />
                          </Tabs>
                        </Box>

                        <TabNumberOfSalesCards data={totalNumberOfSalesData} value={value} />
                        <TabNumberOfSalesChart data={totalNumberOfSalesData} value={value} />
                      </Row>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      CASHBACK
                    </AccordionSummary>
                    <AccordionDetails>
                      <Row className="wrapper">
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                          <Tabs
                            value={value}
                            onChange={handleChange}
                            sx={{
                              "& .MuiTabs-flexContainer": {
                                width: "100%",
                                justifyContent: "center",
                              },
                            }}
                            aria-label="basic tabs example"
                          >
                            <Tab label="Cards" {...a11yProps(0)} />
                            <Tab label="Gráfico" {...a11yProps(1)} />
                          </Tabs>
                        </Box>

                        <TabCashbackCards data={cashbackData} value={value} />
                        <TabCashbackChart data={cashbackData} value={value} />
                      </Row>
                    </AccordionDetails>
                  </Accordion>

                </Container>
              )}
            </div>
          </Container >
        )
        }
      </div >
    </>
  );
}

export default RadarDeVendas;
