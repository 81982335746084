import { Bar } from "react-chartjs-2"
import { calculateWidth } from "./chartOptions"

export const ChartBar = ({ useHeight, height, dataChart, options, shouldCalculateProportion = true }) => {
    console.log({ useHeight, shouldCalculateProportion });

    return <Bar
        height={(shouldCalculateProportion || useHeight) ? height : null}
        width={shouldCalculateProportion ? calculateWidth(dataChart) : null}
        data={dataChart}
        options={options}
    />
}