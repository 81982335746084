import { Stack, Typography } from "@mui/material";
import dayjs from "dayjs";

export const Legenda = ({ subtitles }) => {
  if (!subtitles) {
    return null;
  }
  return (
    <Stack
      gap={0.5}
      width={"100%"}
      alignItems={"center"}
      justifyContent={"center"}
      mb={4}
    >
      <Typography fontSize={"14px"} component={"span"}>
        Legenda
      </Typography>
      <Stack
        alignItems={"center"}
        fontSize={"14px"}
        flexDirection={"row"}
        gap={0.5}
      >
        <strong>AT</strong>
        <Typography fontSize={"14px"} component={"span"}>
          - Ano atual
        </Typography>{" "}
        |<strong>Inicio: </strong>
        <Typography fontSize={"14px"}>
          {dayjs(subtitles.at.start).format("DD/MM/YYYY")}
        </Typography>
        <strong>Fim: </strong>
        <Typography fontSize={"14px"}>
          {dayjs(subtitles.at.end).format("DD/MM/YYYY")}
        </Typography>
      </Stack>
      <Stack
        alignItems={"center"}
        fontSize={"14px"}
        flexDirection={"row"}
        gap={0.5}
      >
        <strong>AN</strong>
        <Typography fontSize={"14px"} component={"span"}>
          - Ano anterior
        </Typography>{" "}
        |<strong>Inicio: </strong>
        <Typography fontSize={"14px"}>
          {dayjs(subtitles.an.start).format("DD/MM/YYYY")}
        </Typography>
        <strong>Fim: </strong>
        <Typography fontSize={"14px"}>
          {dayjs(subtitles.an.end).format("DD/MM/YYYY")}
        </Typography>
      </Stack>
      <Stack
        alignItems={"center"}
        fontSize={"14px"}
        flexDirection={"row"}
        gap={0.5}
      >
        <strong>PA</strong>
        <Typography fontSize={"14px"} component={"span"}>
          - Período anterior
        </Typography>{" "}
        |<strong>Inicio: </strong>
        <Typography fontSize={"14px"}>
          {dayjs(subtitles.pa.start).format("DD/MM/YYYY")}
        </Typography>
        <strong>Fim: </strong>
        <Typography fontSize={"14px"}>
          {dayjs(subtitles.pa.end).format("DD/MM/YYYY")}
        </Typography>
      </Stack>
    </Stack>
  );
};
